.show_more_button {
    display: inline;
    color: #91172C;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
 //   font-weight: bold;
}

.symbols_message {
    background-color: white;
    z-index: 80;
    position: absolute;
    bottom: 85px;
    left: 50%;
    padding: 14px 24px 14px 24px;
    gap: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px #00000014;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #2A2A2A;
}

.post {
    background-color: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 35px;
    padding: 32px;
    width: calc(100% - 10px);

    @media (max-width: 768px) {
      padding: 10px; 
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    &__mainInfo {
        display: grid;
        gap: 4px 12px;
    }
    &__avatar {
        background-color: #FFFFFF;
        border-radius: 50%;
        grid-row: 1 / 3;
        width: 60px;
        height: 60px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
        }
    }
    &__title {
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 24px;
        font-weight: 500;
        line-height: calc(28 / 24);

        @media (max-width: 768px) {
          font-size: 20px; 
        }
    }
    &__createdAt {
        align-self: start;
        color: #9D9D9D;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-family: "Saira", "sans-serif";
        font-size: 18px;
        font-weight: 500;
        line-height: calc(22 / 18);

        @media (max-width: 768px) {
          font-size: 16px; 
        }
    }
    &__body {
        &:not(:last-child) {
            margin: 0 0 24px;
        }
    }
    &__content {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
        white-space: break-spaces;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 7.2em;

        max-width: 70vw;

        &_expanded {
            max-height: none;
        }
        & > * {
            &:not(:last-child) {
                margin: 0 0 24px;
            }
        }
        ul, ol {
            padding-left: 24px;
        }
        li {
            list-style: initial;
        }
        &:not(:last-child) {
           margin: 0 0 20px;
        }

        :global(.post_video_wrapper) {
          margin-top: 20px;

          iframe {
         //   height: auto;
            aspect-ratio: 16/9;
            @media (max-width: 1600px) {
               height: 400px;
                width: 70%;
            }
            @media (max-width: 1000px) {
                width: 100%;
            }
          }
        }
    }
    &__tags {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
        &:not(:last-child) {
            margin: 0 0 24px;
        }
    }
    &__media {
        display: grid;
        gap: 8px;

        /* Для одного изображения — занимает всю ширину */
        //&.post__media_single {
        //    grid-template-columns: 1fr;
        //}
        //
        ///* Для двух изображений — каждая по половине ширины */
        //&.post__media_double {
        //    grid-template-columns: 1fr 1fr;
        //}
        //
        ///* Для трех и более изображений (максимум 4 на ряд) */
        //&.post__media_multiple {
        //    grid-template-columns: repeat(3, 1fr); /* 4 изображения в строке */
        //}

        &_item {
            width: 100%;
            padding-top: 56.25%; /* Пропорция 16:9 */
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            margin: 0;
            & > img, & > video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__footer {
        display: flex;
        gap: 12px;
        //& > * {
        //    &:last-child {
        //        margin-left: auto;
        //    }
        //}
        //&:not(:last-child) {
            margin: 0 0 24px;
    }
    &__tag {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 20px;
        cursor: pointer;
        &Label {
            font-family: "Saira", "sans-serif";
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            &_liked {
                color: #E11F1F;
            }
        }
        &_views {
            background-color: transparent
        };
    }
    &__comments {
        border-top: 2px solid #F8F8F8;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 0 0 64px;

        @media (max-width: 768px) {
          padding: 24px 0 0 0; 
        }

        & > div {
          display: flex;
          justify-content: space-between;
          
          p {
            font-size: 24px;
            font-weight: 500;
          }
          span {
            background-color: var(--black);
            border-radius: 60px;
            padding: 8px 26px;

            color: var(--white);
            font-size: 18px;
            font-weight: 500;
          }
        }
    }
    &__commentsList {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__commentsMore {
        align-self: flex-start;
        color: #91172C;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &__commentsForm {
        textarea {
            background-color: #F8F8F8;
            border: 2px solid #F2F2F2;
            border-radius: 25px;
            color: #6f6f6f;
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            padding: 32px 30px;
            resize: none;
            width: 100%;
            height: 220px;
        }
        .textarea_wrapper {
            position: relative;
        }
        .submit_button {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
}

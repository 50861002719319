.hero {
    // background-image: url();
    background: linear-gradient(228deg, #f23e5d 0%, #91172c 100%);

    @media (max-width: 1440px) {
      height: 480px; 
    }
    @media (max-width: 1000px) {
      height: 420px; 
    }
    @media (max-width: 768px) {
      height: 360px; 
    }
    @media (max-width: 480px) {
      height: 320px; 
    }

    &__title {
        background-color: #91172C;
        backdrop-filter: blur(40px);
        border: 6px solid #FFFFFF;
        top: calc(90 / 560 * 100%);
        left: calc(48 / 1660 * 100%);
        transform: rotate(-15deg);
        transform-origin: center;

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
        @media (max-width: 768px) {
          font-size: 22px; 
        }
    }
    &__subtitle {
        background-color: #FFB800;
        top: calc(155 / 560 * 100%);
        left: calc(167 / 1660 * 100%);
        transform: rotate(-15deg);
        transform-origin: center;
        text-transform: capitalize;

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
        @media (max-width: 768px) {
          font-size: 22px; 
        }
    }
    &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: screen;
    }
    &__ruby {
        top: 50%;
        right: calc(78 / 1660 * 100%);
        transform: translate(0, -50%);

        @media (max-width: 768px) {
          width: 60%;
          bottom: 0;
          top: auto;
          transform: none;
        }
    }
}
button {
  cursor: pointer;
  padding: 12px 30px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 50px;
}

.acceptButton {
  background-color: #91172C;
  color: white;
}

.declineButton {
  border: 2px solid var(--main-color);
  color: var(--main-color);
}

.notification {
  width: 567px;
  background-color: white;
  border-radius: 25px;
  padding: 20px;
  display: flex;
  column-gap: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.avatar {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  p {
    font-size: 20px;
    line-height: 24px;
    span {
      font-weight: 500;
    }
  }
  h2 {
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}
.clubInfo {
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    padding: 0 0 70px;
    overflow: hidden;

    @media (max-width: 1000px) {
      border-radius: 35px; 
    }

    &__image {
        height: 338px;
        overflow: hidden;

        @media (max-width: 1440px) {
          height: 260px; 
        }
        @media (max-width: 480px) {
          height: max-content;
       }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom;

            @media (max-width: 480px) {
               height: 150px;
            }
        }
    }
    &__content {
        display: flex;
        gap: 20px;
        margin: 0 auto;
        width: calc(100% - 140px);

        @media (max-width: 1440px) {
          width: 100%; 
          padding: 0 50px;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          padding: 0 20px;
          gap: 0;
        }
        @media (max-width: 480px) {
          padding: 0 20px; 
        }
    }
    &__info {
      width: 100%;
    }
    &__infoHeader {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 24px 0 0;

        @media (max-width: 600px) {
          flex-direction: column;
          row-gap: 30px; 
        }

        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        &:not(:last-child) {
            margin: 0 0 32px;
        }
    }
    &__avatar {
        border: 6px solid #FFFFFF;
        border-radius: 50%;
        flex-shrink: 0;
        margin: -108px 0 0;
        width: 180px;
        height: 180px;
        overflow: hidden;

        @media (max-width: 1440px) {
          width: 140px;
          height: 140px; 
        }
        @media (max-width: 480px) {
          width: 100px;
          height: 100px;
          margin: -50px 0 0;
       }

        &>div {
          width: 100%;
          height: 100%;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        font-size: 36px;
        font-weight: 600;
        line-height: clac(44 / 36);

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
    }
    &__level {
        background-color: #FF9900;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 6px 20px;
    }
    &__infoDesc {
        p {
            font-size: 20px;
            font-weight: 400;
            line-height: caclc(24 / 20);

            @media (max-width: 1440px) {
              font-size: 18px; 
            }
        }
        &:not(:last-child) {
            margin: 0 0 32px;
        }
    }
    &__author {
    }
    &__infoFooter {
        display: flex;
        gap: 32px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: start;
        }
    }
    &__clients {
        display: flex;

        @media (max-width: 768px) {
          flex-wrap: wrap; 
        }
    }
    &__client_wrapper {
      display: grid;
      justify-items: center;
      div {
        margin-right: -3px!important;
      }
    }
    &__client {
        border: 4px solid #F8F8F8;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        overflow: hidden;
        &:not(:last-child) {
            margin-right: -18px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
        }
    }
}

.club_event {
  cursor: pointer;
}

.section_body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.feed {
    &__nav {
        flex-basis: 100%;
        display: flex;
        gap: 46px;
    }
    &__navButton {
        color: #9D9D9D;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 0 0 20px;
        position: relative;
        &::after {
            background-color: #91172C;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 0;
        }
    }
    &__navButtonActive {
        color: #91172C;
        &::after {
            width: 100%;
        }
    }
    &__content {
        flex-basis: calc(100% - 460px - 60px);
        & > * {
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }
    &__aside {
        border-radius: 35px;
        background-color: #FFFFFF;
        align-self: flex-start;
        flex: 0 0 460px;
        padding: 32px;
        position: sticky;
        top: 50px;
    }
    &__form {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 18px 24px;
        &:not(:last-child) {
            margin: 0 0 32px;
        }
        input {
            background-color: transparent;
            border: none;
            outline: none;
            color: #6F6F6F;
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
        }
        button {
            background-color: transparent;
        }
    }   
    &__filter {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__filterItem {
        background-color: #F8F8F8;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 18px 60px;
        text-align: center;
    }
    &__filterItemActive {
        background-color: #91172C;
        color: #FFFFFF;
    }
}

.author {
    background-color: #F8F8F8;
    border-radius: 50px;
    display: grid;
    align-content: center;
    gap: 4px 16px;
    padding: 6px 34px 6px 6px;
    &__avatar {
        border-radius: 50%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 60px;
        height: 60px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__position {
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        color: #817E7E;
        font-size: 16px;
        font-weight: 500;
        line-height: calc(24 / 16);
    }
    &__name {
        align-self: start;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
}

.news {
    background-color: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 35px;
    padding: 32px;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    &__mainInfo {
        display: grid;
        gap: 4px 12px;
    }
    &__avatar {
        background-color: #FFFFFF;
        border-radius: 50%;
        grid-row: 1 / 3;
        width: 60px;
        height: 60px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
        }
    }
    &__title {
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 24px;
        font-weight: 500;
        line-height: calc(28 / 24);
    }
    &__createdAt {
        align-self: start;
        color: #9D9D9D;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-family: "Saira", "sans-serif";
        font-size: 18px;
        font-weight: 500;
        line-height: calc(22 / 18);
    }
    &__body {
        &:not(:last-child) {
            margin: 0 0 24px;
        }
    }
    &__content {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
        & > * {
            &:not(:last-child) {
                margin: 0 0 24px;
            }
        }
        ul, ol {
            padding-left: 24px;
        }
        li {
            list-style: initial;
        }
        &:not(:last-child) {
            margin: 0 0 28px;
        }
    }
    &__tags {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
        &:not(:last-child) {
            margin: 0 0 24px;
        }
    }
    &__image {
        border-radius: 12px;
        overflow: hidden;
        height: 400px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__footer {
        display: flex;
        gap: 12px;
        & > * {
            &:last-child {
                margin-left: auto;
            }
        }
    }
    &__tag {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 20px;
        span {
            font-family: "Saira", "sans-serif";
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
        }
        &_views {
            background-color: transparent
        };
    }
    &__comments {
        border-top: 2px solid #F8F8F8;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 0 0 24px;
    }
    &__commentsList {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__commentsMore {
        align-self: flex-start;
        color: #91172C;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &__commentsForm {
        textarea {
            background-color: #F8F8F8;
            border: 2px solid #F2F2F2;
            border-radius: 25px;
            color: #6f6f6f;
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            padding: 32px 30px;
            resize: none;
            width: 100%;
            height: 220px;
        }
    }
}

.create_club_event {
  position: relative;
  height: max-content;
  width: auto;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  cursor: pointer;

  border: 6px dotted #48903A;
  border-radius: 50px;

  &.disabled {
    background-color: #F8F8F8;
    border: none;
    pointer-events: none;

    &::before, &::after {
      background-color: var(--black);
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 145px;
    width: 4px;
    background-color: #48903A;
    border-radius: 2px;
  }
  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  span {
    position: absolute;
    bottom: 70px;

    font-size: 24px;
    font-weight: 500;
  }
}

.joined_button {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--black);
  pointer-events: none;
}
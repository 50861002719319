
.auth_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;

  @media (max-width: 1200px) {
    padding: 50px; 
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 40px 20px;
    gap: 24px;
  }
  @media (max-width: 600px) {
    padding: 0;

  }

  h1 {
    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media (max-width: 1000px) {
      font-size: 48px;
    }
    @media (max-width: 768px) {
      font-size: 26px; 
    }
  }

  & > div {
    &:nth-child(1) {
      width: 100%;

      @media (max-width: 768px) {
        max-width: 500px; 
      }

      p {
        @media (max-width: 1000px) {
          font-size: 18px; 
        }
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  .form_wrapper {
    justify-self: end;
    max-width: 450px;
    width: 100%;

    @media (max-width: 768px) {
      justify-self: center; 
    }
    @media (max-width: 600px) {
      button {
        width: 100%;
        justify-content: center;
      }
      & > a {
        width: 100%;
      }
    }
  }
}

.restore_password {
  max-width: 1000px;
  width: 100%;
  margin: 60px auto;

  @media (max-width: 1000px) {
    padding: 50px; 
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 18px;
    }
    & > span {
      position: relative!important;
      top: auto!important;
      left: auto!important;
      transform: none!important;
      font-size: 18px!important;
    }
  }
  @media (max-width: 480px) {
    padding: 40px 20px; 
  }

  button {
    white-space: nowrap;

    @media (max-width: 480px) {
      font-size: 16px; 
    }
  }
}

.input {
	width: 320px;
	height: max-content;
	padding: 20px 30px;
	background-color: #F8F8F8;
	border-radius: 50px;

	color: var(--black);
	font-size: 20px;

	&::placeholder {
		color: #817E7E;
		font-size: inherit;
		font-weight: inherit;
	}

	&:focus {
		outline: 1px solid var(--black);
	}

}
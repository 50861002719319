.hero {
    // background-image: url();
    background: linear-gradient(228deg, #f23e5d 0%, #91172c 100%);

    @media (max-width: 1440px) {
      height: 480px; 
    }
    @media (max-width: 1000px) {
      height: 420px; 
    }
    @media (max-width: 768px) {
      height: 360px; 
    }
    @media (max-width: 480px) {
      height: 320px; 
    }

    &__title {
        background-color: #91172C;
        backdrop-filter: blur(40px);
        border: 6px solid #FFFFFF;
        top: calc(127 / 560 * 100%);
        left: calc(35 / 1660 * 100%);
        transform: rotate(-15deg);
        transform-origin: center;

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
        @media (max-width: 768px) {
          font-size: 22px; 
        }
    }
    &__subtitle {
        background-color: #FFB800;
        top: calc(192 / 560 * 100%);
        left: calc(150 / 1660 * 100%);
        transform: rotate(-15deg);
        transform-origin: center;
        text-transform: capitalize;

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
        @media (max-width: 768px) {
          font-size: 22px; 
        }
    }
    &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: screen;
    }
    &__ruby {
        right: calc(-97 / 1660 * 100%);
        bottom: -24px;

        @media (max-width: 1440px) {
          width: 500px; 
        }
        @media (max-width: 768px) {
          width: 70%;
        }
    }
}

.training {
    .section__counter {
        margin-right: auto;
    }
    .tab-nav {
        margin-left: 20px;
    }

    :global(.section__header) {
      @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
      }
    }
}

.image_card {
  cursor: pointer;
}

.training_videos_modal {
  z-index: 1000;
  
  @media (max-width: 1400px) {
    padding: 0;
  }

  .training_videos_modal_body {
    position: relative;
    background-color: transparent!important;
    display: flex;
    justify-content: center;
    
    @media (max-width: 1400px) {
      max-width: 95vw;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.image_modal_body {
      & > div {
        height: max-content;
      }
    }

    & > div {
      position: relative;
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;
      border-radius: 50px;
      padding-bottom: 20px;

      @media (max-width: 1400px) {
        // height: 100vh;
      }

      &.image_body {
        display: grid;
        align-content: start;
        gap: 30px;
        background-color: var(--white);
        border-radius: 50px;
        padding-bottom: 60px;
        max-height: 85vh;
        overflow-y: auto;

        & > img {
          border-radius: 50px 50px 30px 30px;
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        h2 {
          margin: 40px 0 15px 0;
        }

        b {
          font-size: 64px;
          font-weight: 600;
          margin: 0 100px;

          @media (max-width: 768px) {
            font-size: 32px; 
            margin: 0 20px;
          }
        }

        & > p {
          display: block;
          
        }
  
        p {
          font-size: 24px;
          line-height: 32px;
          margin: 0 100px;

          @media (max-width: 768px) {
            font-size: 18px;
            margin: 0 40px;
          }
        }

        ol {
          li {
            list-style: decimal;
          }
        }

        ul {
          li {
            list-style: disc;
          }
        }
      }


      video {
        width: 100%;
        max-height: 100%;
        // object-fit: contain;
      }

      & > img {
        width: 100%;
        max-height: 90vh;
        height: auto;
        object-fit: contain;
      }
    }
  }
}


.card {
    &__video {
        cursor: pointer;
        background-color: #DEDEDE;
        border-radius: 50px;
        aspect-ratio: calc(540 / 320);
        overflow: hidden;
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    &__content {
        padding: 0 24px 24px 24px;
    }
    &__title {
        color: #2A2A2A;
        font-size: 28px;
        font-weight: 600;
        line-height: calc(32 / 28);
        &:not(:last-child) {
            margin: 0 0 12px;
        }
    }
    &__desc {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
    }
}

.video {
    position: relative;
    &__poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
    }
    &__playbutton {
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -50%);
        img {
          width: 18px;
          height: 18px;
            // transform: translate(2px, 0);
        }
    }
    &__duration {
        background-color: rgba($color: #686868, $alpha: 0.6);
        backdrop-filter: blur(12px);
        border-radius: 50px;
        color: white;
        font-family: "Saira", "sans-serif";
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 6px 14px;
        position: absolute;
        right: calc(26 / 540 * 100%);
        bottom: calc(26 / 320 * 100%);
    }
}

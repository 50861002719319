.page {
    &__title {
    }
    &__content {
        
    }
}

.form {
    &__body {
        background-color: var(--white);
        flex-direction: column;
        gap: 50px;
    }
    &__footer {
        background-color: #F8F8F8;
    }
}

.preview {
    border-top: 2px solid #F8F8F8;
    border-bottom: 2px solid #F8F8F8;
    padding: 32px 0;
    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: calc(28 / 24);
        margin: 0 0 24px;
    }
    &__row {
        display: flex;
        gap: 20px;
        & > * {
            border-radius: 35px;
            flex-basis: calc((100% - 7 * 20px) / 8);
        }
    }
    &__skeleton {
        aspect-ratio: 1;
        border: 4px dashed #817e7e;
        position: relative;
        &::before, &::after {
            background-color: #817e7e;
            border-radius: 100px;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 32px;
            height: 4px;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(90deg);
        }
        &::after {
            transform: translate(-50%, -50%)
        }
    }
    &__thumb {
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 50px;
    & > * {
        grid-column: span 2;
    }
}

.formControl {
    &__innerBody {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    &__labelWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__label {
        font-size: 24px;
        font-weight: 500;
        line-height: calc(28 / 24);
        margin: 0 0 24px;
    }
    &__field {
        background-color: #F8F8F8;
        border-radius: 50px;
        color: #817E7E;
        font-family: "Raleway";
        font-size: 18px;
        font-weight: 400;
        line-height: calc(22 / 18);
        width: 100%;
    }
    textarea.formControl__field {
        padding: 30px;
        resize: none;
        height: 218px;
    }
    input.formControl__field {
        padding: 20px 30px;
    }
}


.popup {
    &__content {
        border-radius: 16px;
        padding: 20px;
        max-width: 424px;
    }
    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 8px;
        text-align: center;
        &:not(:last-child) {
            margin: 0 0 40px;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
    }
}

.time {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.timeControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0 10px;
    &__up, &__down {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
    }
    &__up {
        transform: rotate(180deg);
    }
    &__input {
        background-color: #F8F8F8;
        border-radius: 25px;
        font-family: "Saira", "sans-serif";
        font-size: 40px;
        font-weight: 500;
        line-height: calc(48 / 40);
        width: 130px;
        height: 130px;
        text-align: center;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
           -webkit-appearance: none;
        }
    }
}

.timeInput {
    background-color: #F8F8F8;
    border-radius: 50px;
    color: #817E7E;
    cursor: pointer;
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 400;
    line-height: calc(22 / 18);
    position: relative;
    padding: 20px 30px;
    width: 100%;
    img {
        position: absolute;
        top: 19px;
        right: 30px;
    }
}

.successPopup {
    &__content {
        border-radius: 50px;
        padding: 70px;
        max-width: 1660px;
    }
    &__body {
        display: flex;
        gap: 60px;
    }
    &__info {
        color: #3E3E3E;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    &__title {
        font-size: 64px;
        font-weight: 600;
        line-height: calc(76 / 64);
    }
    &__text {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
    }
    &__actions {
        display: flex;
        gap: 18px;
        margin-top: auto;
    }
}
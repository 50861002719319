.dropdown {
    position: relative;
  z-index: 99;
    &__button {
        background-color: #F8F8F8;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3px;
        width: 48px;
        height: 48px;
        padding: 0;
        
        span {
            background-color: #000000;
            border-radius: 50%;
            width: 4px;
            height: 4px;
        }
    }
    &__menu {
        background-color: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px;  
        position: absolute;
        top: calc(100% + 18px);
        right: 0;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, -10%);
    }
    &__menuItem {
        color: #000000;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        white-space: nowrap;
        cursor: pointer;
    }
    &_open {
        .dropdown__menu {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }
    }
}

.complaint_modal_body {
  border-radius: 50px;
  padding: 50px;

  @media (max-width: 1440px) {
    padding: 30px;
    border-radius: 26px;
  }

  form {
    display: grid;
    gap: 36px;

    textarea {
      background-color: #F8F8F8;
      border: 2px solid #F2F2F2;
      border-radius: 25px;
      color: #6f6f6f;
      font-size: 18px;
      font-weight: 500;
      line-height: calc(24 / 20);
      padding: 32px 30px;
      resize: none;
      width: 100%;
      height: 220px;

      &::placeholder {
        opacity: .6;
      }
    }

    & > button {
      margin: 0 auto;
    }

  }

  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
  }

  .subtitle {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }

  .text {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .title {
      grid-column: 1/3;

      @media (max-width: 768px) {
        grid-column: 1/2;
      }
    }

    p {
      font-size: 20px;
      font-weight: 400;

      span {
        color: var(--main-color);
        font-weight: 500;
      }
    }

    &> span {
      color: #A0A0A0;
      font-size: 20px;
      white-space: nowrap;
    }
  }

  .select {
    width: max-content;
    z-index: 10;

    @media (max-width: 600px) {
      width: 100%; 
    }
    
    button {
      width: 400px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 60px;
      border-radius: 36px;
      padding: 30px;
      background-color: transparent;
      border: 2px solid rgb(238, 238, 238);

      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;

      @media (max-width: 600px) {
        width: 100%;
        gap: 10px;
      }

      &._active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    & > ul {
      width: 100%;
      border-radius: 36px;
    }

  }


}

.complaint_success {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
}
.breadcrumb {
  margin-bottom: 0 !important;

  li {
    &.active {
      color: var(--main-color);
    }
  }
}

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  color: #817E7E;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

:root {
  --main-color: #91172C;
  --white: #FFFFFF;
  --black: #2A2A2A;
  --light-grey: #F8F8F8;
  --color-primary: #0083FC;
  --color-accent: #FF5533;
  --box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  display: none;
}

.leaflet-control-attribution {
  display: none;
}

body {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  // padding-right: 20px;

  &._lock {
    overflow: hidden;
    height: 100%;
  }
}

#root {

}

a {
  text-decoration: none;
  color: inherit;
}

img {
  vertical-align: top;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

button[type="button"] {
  cursor: pointer;
}

input, textarea {
  border: none;
  outline: none;
}

li {
  list-style: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

@for $i from 1 through 100 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

.page {
  & > * {
    &:not(:last-child) {
      margin: 0 0 160px;

      @media (max-width: 1440px) {
        margin: 0 0 100px;
      }
    }
  }
}

.section__title {
  @media (max-width: 1440px) {
    font-size: 36px!important;
  }
  @media (max-width: 1000px) {
    font-size: 32px!important;
  }
  @media (max-width: 480px) {
    font-size: 26px!important; 
  }
}

.register_mobile {
  display: none !important;
  @media screen and (max-width: 600px) {
    display: block !important;
  }
}

.levelButton {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.011em;
  text-align: left;
  background-color: #FF9900;
  border-radius: 30px;
  padding: 6px 13px;
  color: white;
  span {
    font-family: Saira;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.011em;
    text-align: left;
  }
}

.userList {
  padding: 70px;
  border-radius: 50px;
  background-color: #F8F8F8;
  display: flex;
  row-gap: 40px;
  column-gap: 60px;
  &_list {
    width: 65%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
}

.section {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin: 0 0 46px;
    }
  }
  &__title {
    font-size: 40px;
    font-weight: 500;
    line-height: calc(48 / 40);
    margin-right: 20px;
  }
  &__counter {
    background-color: #2A2A2A;
    border-radius: 50px;
    color: #FFFFFF;
    font-family: "Saira", "sans-serif";
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
    padding: 12px 30px;

    @media (max-width: 480px) {
      padding: 8px 20px; 
    }
  }
  &__body {

  }
  &__slider {
    .swiper-slide {
      height: auto;
      & > * {
        height: 100%;
      }
      & > a {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
  &__sliderControls {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 46px 0 0;
  }
  &__sliderButton {

  }
  &--big {
    padding: 160px 130px 200px;
  }
  &--rounded {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    margin: -32px 0 0;
    // position: relative;
    // z-index: 2;
  }
  &--orange {
    background-color: #FFB800;
  }
  &--green {
    background-color: #51CC39;
  }
  &--vector-bg {
    position: relative;
    z-index: 2;
    &::before {
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      position: absolute;
      inset: 0;
      mix-blend-mode: screen;
      z-index: -1;
    }
    .section__title {
      color: #FFFFFF;
      position: relative;
      z-index: 1;

      &::before {
        border-radius: 50px;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 36px * 2);
        height: calc(100% + 14px * 2);
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }
  // &:not(:last-child) {
  //   margin: 0 0 160px;
  // }
}

.button {
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 18px 60px;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 14px 26px;
  }

  span {
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);

    @media (max-width: 1440px) {
      font-size: 18px;
    }
  }
  &--black {
    background-color: var(--black);
    color: var(--white);
  }
  &--white {
    background-color: var(--white);
    color: var(--black);
  }
  &--green {
    background-color: #48903A;
    color: var(--white)
  }
  &--main {
    background-color: var(--main-color);
    color: var(--white);
  }
  &--main-outlined {
    background-color: transparent;
    border: 2px solid var(--main-color);
    color: var(--main-color);
  }
  &:disabled {
    background-color: rgba(180, 180, 180, 0.65);
    cursor: auto;
  }
}

.row {
  display: flex;
  gap: 20px;
  & > div {
    flex: 0 0 calc((100% - 2 * 20px) / 3);
  }
}

.grid {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
   // background-color: #F8F8F8;
   // padding: 70px;
    border-radius: 35px;

    @media (max-width: 1540px) {
      grid-template-columns: 1fr 1fr 1fr !important;
      padding: 50px;
    }
    @media (max-width: 1100px) {
      grid-template-columns: 1fr 1fr !important;
    }
    @media (max-width: 768px) {
      padding: 20px;
      grid-template-columns: 1fr !important;
    }

    &:not(:last-child) {
      margin: 0 0 100px;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    } 
  }
  &__pagination {

  }
  &__buttons {
    display: flex;
    gap: 20px;
  }
  &__button {
    cursor: pointer;
    &--prev {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.popup {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  // opacity: 0;
  // visibility: hidden;
  // transition: all 0.3s ease 0s;
  z-index: 15;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    min-height: 100%;
  }
  &__content {
    background-color: #FFFFFF;
    position: relative;
    width: 100%;
    transition: all 0.3s ease 0s;
  }
}
.pagination {
  &__list {
    display: flex;
    gap: 6px
  }
  &__item {
  }
  &__button {
    background-color: #F8F8F8;
    border-radius: 50%;
    cursor: pointer;
    color:  #2A2A2A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    span {
      font-family: "Saira", "sans-serif";
      font-size: 20px;
      font-weight: 500;
      line-height: calc(24 / 20);
    }
    &--active {
      background-color: #2A2A2A;
      color: white;
    }
    &--active, &--ellipsis {
      pointer-events: none;
    }
  }
}

.tab-nav {
  background-color: #F8F8F8;
  border-radius: 50px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.tab-button {

  @media (max-width: 1440px) {
    font-size: 18px;
    padding: 14px 30px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }

  &--active {
    background-color: #91172C;
    color: white;
  }
}

.filter {
  position: relative;
  &__button {
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &__menu {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translate(0, -10%);
    transition: all 0.3s ease 0s;
  }
  &__menu-list {
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    width: 397px;
  }
  &__menu-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 18px 30px;
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: calc(24 / 20);
    }
    &:not(:last-child) {
      border-bottom: 2px solid #F8F8F8;
    }
  }
  &__menu-arrow {

  }
  &--open {
    .filter__menu {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}

.big-card {
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  padding: 0 0 70px;
  overflow: hidden;

  @media (max-width: 1440px) {
    border-radius: 26px; 
  }

  &__cover {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    height: 338px;
    position: relative;
    overflow: hidden;

    @media (max-width: 1440px) {
      height: 260px; 
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  &__edit-cover {
    display: none;
  }
  &__content {
    display: flex;
    gap: 20px;
    margin: 0 auto;
    width: calc(100% - 140px);

    @media (max-width: 1000px) {
      width: 100%; 
      padding: 0 50px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
    }
    @media (max-width: 480px) {
      padding: 0 20px; 
    }
  }
  &__avatar {
    border: 6px solid #FFFFFF;
    border-radius: 50%;
    box-sizing: content-box;
    flex-shrink: 0;
    margin: -76px 0 0;
    position: relative;
    width: 180px;
    height: 180px;
    // overflow: hidden;

    @media (max-width: 1440px) {
      width: 140px;
      height: 140px; 
    }

    picture {
      border-radius: 50%;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--profile {
      background-color: #F7304C;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      overflow: visible;
      picture {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 124px;
          height: 124px;
        }
      }
    }
  }
  &__avatar-status {
    background-color: #44E226;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    position: absolute;
    bottom: 0;
    right: calc(20 / 180 * 100%);
    width: 20px;
    height: 20px;

    @media (max-width: 1440px) {
      width: 15px;
      height: 15px; 
    }
  }
  &__edit-avatar {
    display: none;
  }
  &__info {
    flex-grow: 1;

    @media (max-width: 1000px) {

    }
  }
  &__info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 0 0;

    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 20px;
    }

    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
    }
    &:not(:last-child) {
      margin: 0 0 32px;
    }
  }
  &__title {
    font-size: 36px;
    font-weight: 600;
    line-height: clac(44 / 36);

    @media (max-width: 1440px) {
      font-size: 32px; 
    }
  }
  &__level {
    background-color: #FF9900;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
    padding: 6px 20px;

    @media (max-width: 1440px) {
      font-size: 16px; 
    }

    span {
      font-family: "Saira", "sans-serif";
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
    @media (max-width: 1000px) {
      align-items: flex-start; 
    }

    button {
      @media (max-width: 1440px) {
        font-size: 16px; 
        padding: 18px 30px;

        span {
          font-size: 16px; 
        }
      }
    }
  }
  &__info-body {
    &:not(:last-child) {
      margin: 0 0 32px;
    }
  }
  &__form {
    max-width: 904px;
    textarea {
      background-color: #F8F8F8;
      border: 2px solid #F2F2F2;
      border-radius: 25px;
      color: #6F6F6F;
      font-size: 20px;
      font-weight: 500;
      line-height: calc(24 / 20);
      padding: 32px 30px;
      width: 100%;
      height: 220px;
      resize: none;
      &:not(:last-child) {
        margin: 0 0 32px
      }
    }
    button[type="submit"] {
      cursor: pointer;
    }
  }
  &__info-desc {
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: caclc(24 / 20);

      @media (max-width: 1440px) {
        font-size: 16px; 
      }
    }
  }
  &__info-footer {
    display: flex;
    gap: 32px;
  }
  &__users {
    background-color: #F8F8F8;
    border-radius: 50px;
    padding: 6px 34px 6px 0px;
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &__users-list {
    display: flex;
    position: relative;
    z-index: 1;
  }
  &__user {
    background-color: #F8F8F8;
    border: 4px solid #F8F8F8;
    border-radius: 50%;
    box-sizing: content-box;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: -20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  &__users-qty {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &_friends {
      color: #817E7E;
      font-size: 16px;
      font-weight: 500;
      line-height: calc(20 / 16);
    }
    &_friendsCount {
      font-family: "Saira", "sans-serif";
      font-size: 20px;
      font-weight: 500;
      list-style: calc(24 / 20)
    }
  }
  &--edit {
    .big-card__edit-cover {
      background-color: #2A2A2A;
      border-radius: 50px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 30px;
      position: absolute;
      cursor: pointer;
      top: 32px;
      right: 32px;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
      }
    }
    .big-card__avatar-status {
      display: none;
    }
    .big-card__edit-avatar {
      background-color: var(--black);
      border: 6px solid var(--white);
      border-radius: 50%;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 48px;
      cursor: pointer;
      height: 48px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.form {
  border-radius: 50px;
  box-shadow: var(--box-shadow);
  overflow: hidden;

  &__body {
    background-color: var(--light-grey);
    display: flex;
    gap: 30px;
    padding: calc(70 / 1660 * 100%);

    @media (max-width: 1440px) {
      padding: 50px; 
    }
    @media (max-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px;
      gap: 20px;
    }
    @media (max-width: 1000px) {
      grid-template-columns: 1fr; 
    }
    @media (max-width: 480px) {
      padding: 10px; 
    }
  }
  
  &__column {
    background-color: var(--white);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 15px);
    gap: 32px;
    padding: 35px;

    @media (max-width: 480px) {
      padding: 30px 16px; 
    }
  }
  &__control {

  }
  &__footer {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    padding: 46px 20px;

  }
}

.form-control {
  &__label {
    font-size: 24px;
    font-weight: 500;
    line-height: calc(28 / 24);
    margin: 0 0 24px;

    @media (max-width: 1440px) {
      font-size: 22px; 
    }
    @media (max-width: 1200px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__field {
    background-color: var(--light-grey);
    border-radius: 50px;
    color: #817E7E;
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 400;
    line-height: calc(22 / 18);
    width: 100%;
  }
  &__field-wrapper {
    position: relative;
    img {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }
  &__inner-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  &__switch {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__switch-text {
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
  }
  &__switch-element {
    background-color: #817E7E;
    border-radius: 20px;
    cursor: pointer;
    padding: 2px;
    position: relative;
    width: 50px;
    height: 28px;
    transition: background-color 0.2s ease 0s;
    &::after {
      background-color: #FFFFFF;
      border-radius: 50px;
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 24px;
      height: 24px;
      transition: left 0.2s ease 0s;
    }
    &_on {
      background-color: #91172C;
      &::after {
        left: calc(100% - 26px);
      }
    }
  }
  input.form-control__field {
    padding: 20px 30px;

    @media (max-width: 1200px) {
      padding: 15px 30px;
    }
  }
  textarea.form-control__field {
    padding: 30px;
    resize: none;
    height: 218px;
  }
  &__field-wrapper {

  }
}
.react-datepicker {
  border: none;
  border-radius: 16px;
  padding: 20px;
}

.react-datepicker__input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-datepicker__calendar-icon {
  padding: 0 !important;
  left: auto;
  top: 24px !important;
  right: 30px !important;
}

.react-datepicker__navigation {
  top: 22px
}

.react-datepicker__navigation--next {
  right: 22px;
}

.react-datepicker__navigation--previous {
  left: 22px;
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
}

.react-datepicker__current-month {
  font-size: 20px;
  font-weight: 500;
  line-height: calc(24 / 20);
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day--selected {
  background-color: #91172C;
}

.react-datepicker__day--selected:hover {
  background-color: #91172C;
}

.react-datepicker__input-time-container {
  margin-bottom: 20px;
}

.react-datepicker-time__caption {
  font-size: 20px;
}

.react-datepicker-time__input {
  font-size: 20px;
}

.react-datepicker__children-container {
  margin: 20px auto 0;
  width: fit-content;
}

.hero {
  border-radius: 50px;
  position: relative;
  height: 560px;
  overflow: hidden;
  & > * {
    position: absolute;
  }
  &__title {
    border-radius: 60px;
    color: white;
    font-size: 56px;
    font-weight: 500;
    line-height: 1.2;
    padding: 12px 60px;
    text-transform: uppercase;
  }
  &__bg {
  }
  &__ruby {

  }
}

.persons {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    & > div {
      width: calc((100% - 3 * 20px) / 4);
    }
  }
}


.content {
  margin: 0 auto;
  max-width: 90%;

  @media (max-width: 1440px) {
    padding: 0 50px;
    margin: 0;
    max-width: none;
    width: 100%;
  }
  @media (max-width: 1000px) {
    padding: 0 20px;
  }
}

.content__restore {
  margin: 0 auto;
  max-width: 50%;
}

.justify_content_SB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify_content_Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start_button {
  cursor: default;
  background-color: #F8F8F8;
  padding: 6px 6px 6px 30px;
  display: flex;
  align-items: center;
  column-gap: 18px;
  border-radius: 50px;
  & > div {
    padding: 12px 36px 12px 30px;
    font-size: 20px;
    line-height: 30px;
    border-radius: 50px;
    background-color: #368826;
    color: white;
    font-family: 'Saira', sans-serif;
  }
  & > span {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    line-height: 28px;
  }
}

.start_button-green > div {
  background-color: #368826;
}

.start_button-red > div {
  background-color: #E1193A;
}

.start_button_minimized {
  white-space: nowrap;
  padding: 12px 30px;
  background-color: #368826;
  color: white;
  font-size: 20px;
  line-height: 30px;
  border-radius: 50px;
  font-family: 'Saira', sans-serif;
}

.start_button_minimized-red {
  background-color: #E1193A;
}

.start_button_minimized-blue {
  background-color: #489BD7;
}

.action_button {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  padding: 18px 60px;
  column-gap: 12px;
  background-color: #2A2A2A;
  border-radius: 50px;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 18px;
    padding: 14px;
  }
}

.profile_button {
  position: relative;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background-color: #91172C;
  border-radius: 50px;
  padding: 6px 20px 6px 6px;

  @media (max-width: 480px) {
    position: static;
    padding: 4px;
    column-gap: 4px;
    border-radius: 36px; 
  }

  & > span {
    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 480px) {
      display: none; 
    }
  }

  & > img {
    z-index: 12;
  }
}

.profile_button_avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  z-index: 12;

  @media (max-width: 1440px) {
    width: 38px;
    height: 38px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.submenu {
  width: 360px;
  top: 55px;
  right: 0px;
  z-index: 10;
  position: absolute;
  box-shadow: 0px 0px 8px 0px #0000001F;
  border-radius: 35px;
  background: white;
  overflow: hidden;

  a {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    color: #2A2A2A;
    padding: 19px 30px;

    &:not(._active):hover {
      background: #F8F8F8;
    }

    &._active {
      background-color: #91172C;
      color: #FFFFFF;
      // pointer-events: none;
    }
  }
}

.profile_button_subMenu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
  
  @media (max-width: 480px) {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  ul {
    width: 397px;
    box-shadow: 0px 0px 8px 0px #0000001F;
    top: 90px;
    right: 0px;
    background-color: white;
    position: absolute;
    border-radius: 35px;
    overflow: hidden;
    padding-bottom: 10px;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
      margin: 0 10px;
      border-radius: 24px;
    }

    & > div {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 13px;
      background-color: var(--main-color);

      span {
        color: var(--white);
      font-size: 20px;
      }

      & > div {
        width: 48px;
        height: 48px;
      }
    }
  }

  li {
    &:last-child {
      padding: 19px 30px;

    }
  }
  a {
    padding: 19px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;

    @media (max-width: 1440px) {
      padding: 14px 30px;
    }

    &._active {
      background-color: #91172C;
      span {
        color: #FFFFFF;
      }
    }
  }
  span {
    white-space: nowrap;
    color: #2A2A2A;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }
}

.cabinet_button {
  cursor: pointer;
  color: white;
  padding: 18px 60px;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  background-color: #91172C;
}

.bordered_button_white {
  cursor: pointer;
  color: #2A2A2A;
  display: flex;
  align-items: center;
  padding: 18px 60px;
  background-color: white;
  border-radius: 50px;
  font-size: 20px;
  line-height: 24px;
  border: 2px solid #2A2A2A;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.white_button {
  cursor: pointer;
  padding: 18px 60px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 60px;
  color: #2A2A2A;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 1440px) {
    font-size: 18px;
  }
}

.white_button_little {
  cursor: pointer;
  background: #FFFFFF;
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.black_button_little {
  cursor: pointer;
  background: black;
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;
}

.orange_button {
  background-color: #FF9900;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  border-radius: 50px;
  padding: 12px 30px;
}

.orange_button_little {
  font-family: 'Saira', sans-serif;
  background-color: #FF9900;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.011em;
  text-align: center;
  padding: 6px 13px;
  border-radius: 30px;
}

.gray_button {
  background-color: #9D9D9D;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  border-radius: 50px;
  padding: 12px 30px;
}

.green_button {
  cursor: pointer;
  border-radius: 60px;
  padding: 18px 60px;
  background-color: #48903A;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

}

.header_unauth {
  padding-top: 28px;
  padding-bottom: 8px;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    &:nth-child(1) {
      img {
        @media (max-width: 768px) {
          width: 167px; 
        }
      }
    }
    &:nth-child(2) {
      img {
        @media (max-width: 768px) {
          width: 167px; 
        }
      }
      div {
        @media (max-width: 768px) {
          font-size: 14px; 
        }
      }
    }
  }
}

.header_restore {
  padding-top: 28px;
  padding-bottom: 25px;
}

.authBlock__restore {
  display: flex;
  flex-direction: column;
  padding: 100px;
  border-radius: 50px;
  margin-top: 60px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.authBlock__restore_text {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.authBlock {
  color: #2a2a2a;
  display: flex;
  justify-content: space-between;
  column-gap: 20%;
  padding: 100px;
  border-radius: 50px;
  margin-top: 60px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 4px 0px #00000040;

  @media (max-width: 600px) {
    box-shadow: none;
  }
}

.h1_36_44 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
}

.authBlock__h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 76px;
}

.authBlock__h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  @media (max-width: 600px) {
    font-size: 20px;
    align-self: flex-start;
  }
}

.authBlock__paragraph {
  font-size: 24px;
  line-height: 28px;
}

.authBlock__text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.authBlock__form {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 26px;

  @media (max-width: 600px) {
    row-gap: 16px; 
  }
}

.authBlock__form_restore {
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-right: auto;
}

.authBlock__form_inputs {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;

  @media (max-width: 600px) {
    row-gap: 6px; 
  }
}

.authBlock__form_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 46px;

  @media (max-width: 600px) {
    row-gap: 24px; 
  }
}

.authBlock__form-group {
  display: flex;
  justify-content: center;
  min-width: 100%;

  input {
    width: 100%;
    border: none;
    background-color: #F2F2F2 !important;
    outline: none;
    padding: 20px 30px;
    border-radius: 50px;
    color: #817E7E !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 18px;
    line-height: 20px;

    &:focus {
      outline: 2px solid black;
    }

    @media (max-width: 600px) {
      padding: 14px 20px;
    }
  }
}

input:-webkit-autofill{
  -webkit-text-fill-color: #817E7E;
  -webkit-box-shadow: 0 0 0px 1000px #F2F2F2 inset !important;
  font-family: 'Raleway', sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
input:-webkit-autofill:focus{
  -webkit-text-fill-color: #817E7E;
  -webkit-box-shadow: 0 0 0px 1000px #F2F2F2 inset !important;
  font-family: 'Raleway', sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.page {
  margin-top: 60px;
  margin-bottom: 160px;
  &__title {
    font-size: 40px;
    font-weight: 500;
    line-height: calc(48 / 40);
    &:not(:last-child) {
      margin: 0 0 46px;
    }
  }
}

.image {
  width: 100%;
  height: 560px;
  border-radius: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
  }
}

.content_after_image {
  margin-top: 160px;

  @media (max-width: 600px) {
    margin-top: 60px; 
  }
}

.newsBlock {
  background-size: cover;
  width: 100%;
  height: 60vh;
  border-radius: 35px;
  padding: 26px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newsBlock_header {
  display: flex;
  justify-content: flex-start;
}

.newsBlock_date {
  font-family: 'Saira', sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  padding: 6px 14px;
  border-radius: 50px;
  background: #68686899;
}

.newsBlock_footer {
  color: white;
  font-size: 1.4vw;
  font-weight: 500;
  line-height: 2vw;
  text-align: left;
}

.shadowBlock {
  padding: 70px;
  border-radius: 50px;
  box-shadow: 0px 0px 8px 0px #0000001F;
}

.news_actual {
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
  max-height: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_text {
    top: 25%;
    right: 5%;
    position: absolute;
    width: 47%;
    font-family: Raleway;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: right;
    color: white;
  }
}

.swiper-slide--fixed {
  flex: 0 0 calc(49%); /* Увеличивает ширину в три раза */
  max-width: calc(300%);
}

@media screen and (max-width: 1600px) {
  .swiper-slide--fixed {
    // min-height: 540px;
    width: 85vw !important;
  }

  .image {
    height: 500px;
  }

  .news_actual {
    &_text {
      right: 4%;
      font-size: 28px;
      line-height: 32px;
      top: 15%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .swiper-slide--fixed {
    flex: 0 0 calc(100%);
  }

  .news_actual {
    &_text {
      right: 4%;
      font-size: 24px;
      line-height: 28px;
      top: 10%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .swiper-slide--fixed {
    width: 90vw !important;
  }
  .news_actual {
    &_text {
      right: 5%;
      font-size: 24px;
      line-height: 32px;
      top: 20%;
    }
  }
}

@media screen and (max-width: 768px) {
  .swiper-slide--fixed {
    width: 85vw !important;
  }


  .news_actual {
    &_text {
      right: 5%;
      font-size: 24px;
      line-height: 32px;
      top: 20%;
    }
  }
}

@media screen and (max-width: 600px) {
  //.swiper-slide--fixed {
  //  width: auto !important;
  //}
}

@media screen and (max-width: 550px) {
  .news_actual {
    &_text {
      right: 4%;
      font-size: 18px;
      line-height: 22px;
      top: 15%;
    }
  }
}

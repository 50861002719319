.footer {
  // position: relative;
  border-top: 3px solid #F8F8F8;
  margin-bottom: auto;
}

.footer_number {
  display: grid;
  white-space: nowrap;

  @media (max-width: 1000px) {
    grid-row: 2/3; 
  }
  
  h3 {
    font-family: Saira;
    font-size: 32px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}

.footer_whitePart {
  padding: 4% 50px;
  column-gap: 5vw;
  max-width: none;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: start;

  @media (max-width: 1600px) {
    grid-template-columns: 20% auto auto;
  }
  @media (max-width: 1440px) {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    gap: 60px 100px;
    padding: 4% 50px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.footer_redPart {
  position: absolute;
  left: 0;
  width: 100vw;
  // transform: translate(-50%, 0);
}

.footer_logos {
  width: 100%;
  display: flex;
  // align-items: center;
  column-gap: 32px;

  @media (max-width: 1600px) {
    column-gap: 24px;

    img {
      &:nth-child(1) {
        width: 56px;
      }
      &:nth-child(2) {
        width: 180px;
      }
    }
  }
  @media (max-width: 1000px) {
    justify-self: center;
    grid-row: auto; 
  }
}

.footer_links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  @media (max-width: 1440px) {
    grid-column: 2/3;
    grid-row: 1/3;

    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    grid-column: auto;
    grid-row: auto;
  }

  & > div {
    width: 100%;
    display: grid;
    align-content: start;
    gap: 10px;

    @media (max-width: 1440px) {
      width: max-content; 
    }

    a {
      padding: 5px 0;
      
      div {
        font-weight: 300;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

.footer_links_flex {
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.footer_link {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.socialMedia_flex {
  margin-top: 26px;
  display: flex;
  column-gap: 12px;
}

.socialMedia {
  width: 48px;
  height: 48px;
  background-color: #F6F6F6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_redPart {
  background-color: #91172C;
  
  & > div {
    padding: 50px 130px;

    @media (max-width: 1000px) {
      padding: 40px 50px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 40px; 
    }
  }

  p {
    color: white;
  }
}

.footer_redPart_right {
  display: flex;
  flex-direction: column;
  row-gap: 26px;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 50px;
    justify-content: center;
  }

  p {
    cursor: pointer;
    font-size: 18px;
    line-height: 22px;
  }
}
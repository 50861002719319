.userName {
  font-size: 20px;
}

.rubickWrapper {
  position: relative;
  display: inline-block;
  margin-left: auto;
  min-width: 104px;
  max-width: 104px;
  max-height: 36px;
}

.rubick {
  max-height: 36px;
  font-family: 'Saira', sans-serif;
  color: #2A2A2A;
  background-color: white;
  padding: 6px 8px;
  min-width: 104px;
  max-width: 104px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  border-radius: 30px;
  font-weight: 500;
}

.tooltip {
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}

.rubickWrapper:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(300%);
}

.awardNotify {
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-family: 'Saira', sans-serif;
  background-color: #E11F1F;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.authHeader {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.exitMenu {
  border-top: 2px solid #F8F8F8
}

.authHeader_up {
  display: flex;
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 12px;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  img {
    @media (max-width: 768px) {
      width: 185px;
    }
    @media (max-width: 480px) {
      width: 160px;
    }
  }
}

.cabinet_button {
  cursor: pointer;
  color: white;
  padding: 18px 60px;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  background-color: #91172C;
  white-space: nowrap;

  @media (max-width: 1000px) {
    padding: 16px 20px;
  }
  @media (max-width: 600px) {
    padding: 10px;

    span { 
      display: none;
    } 
  }

  svg {
    display: none;

    @media (max-width: 600px) {
      display: block;
    }
  }
}

.header_button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 24px 6px 6px;
  border-radius: 60px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

  color: var(--main-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 16px;
    padding: 2px 14px 2px 2px;
  }
  @media (max-width: 1100px) {
    display: none;
  }

  svg {
    height: 48px;
  }
}

.authHeader_menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  border-radius: 50px;
  padding: 8px;
  background-color: #F8F8F8;

  @media (max-width: 1300px) {
    position: fixed;
    right: 0;
    width: 500px;
    min-height: 100vh;
    z-index: 1000;
    display: grid;
    align-items: baseline;
    align-content: start;
    justify-content: stretch;
    gap: 6px;
    padding: 98px 30px 0 30px;
    background-color: var(--main-color);
    border-radius: 0;
    overflow-y: auto;

    transition: all .5s ease;
    transform: translate(150%, 0);

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: calc(100% - 74px); 
      background-color: #F8F8F8;
      border-radius: 26px 26px 0 0;
      z-index: 0;
    }

    &._active {
      transform: translate(0%, 0);
    }

    & > img {
      display: block!important;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
      width: 160px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 98px 10px 0 10px;
  }

  & > img {
    display: none;
  }

  & > a, & > div {
    z-index: 2;
  }

  & > a {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 1300px) {
      display: block;
      justify-content: start;
      color: var(--black);
      background-color: var(--white);
      padding: 14px 24px;
      width: 100%;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: var(--white);
    border-radius: 50%;

    cursor: pointer;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: var(--main-color);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.menuLink {
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
  cursor: pointer;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 22px;
  color: black;
  border-radius: 50px;
  background-color: white;
  white-space: nowrap;

  @media (max-width: 1440px) {
    font-size: 15px;
    padding: 8px 18px;
  }
  @media (max-width: 1300px)  {
    border-radius: 24px;
    color: var(--black);
    font-size: 18px;
    display: grid;
    gap: 10px;
    padding: 14px 24px;
  }

  & > div:first-child {
    display: flex;
    gap: 20px;

    @media (max-width: 1300px) {
      width: 100%;
      background-color: var(--white);
      color: var(--black);
      justify-content: space-between;
    }
  }

  &_active {
    background-color: #91172C!important;
    color: #FFFFFF!important;
  }

  :global(.submenu) {
    @media (max-width: 1300px) {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      overflow: visible;
      box-shadow: none;
    }

    a {
      @media (max-width: 1300px) {
        padding: 10px 20px;
        font-weight: 400;
      }
      @media (max-width: 480px) {
        font-size: 16px; 
      }
    }
  }
}

.menuSearchbar {
  min-width: 60px;
  width: 100%;
  max-width: 360px;
  position: relative;

  @media (max-width: 1500px) {
    width: 60px;
    background-color: #FFFFFF;
    border-radius: 50px;
  }
  @media (max-width: 1300px)  {
    width: 100%;
  }
}

.menuSearchbar input {
  width: 100%;
  padding: 12px 12px 12px 2vw;
  color: #6F6F6F;
  background-color: #EEEEEE;
  border-radius: 50px;
  outline: none;
  border: none;

  @media (max-width: 1500px) {
    opacity: 0;
  }
  @media (max-width: 1300px)  {
    opacity: 1;
    padding-left: 40px;
  }
}

.menuSearchbar img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;

  @media (max-width: 1500px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 1300px)  {
    left: 10px;
    transform: translateY(-50%);
  }
}

.sub_menu_button {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 50%;
  background-color: var(--main-color);
  cursor: pointer;

  @media (max-width: 1300px) {
    display: flex;
  }
  @media (max-width: 480px) {
    padding: 10px; 
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
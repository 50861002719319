.settings {
    &__title {
        color: #000000;
        font-size: 40px;
        font-weight: 500;
        line-height: calc(48 / 40);
        overflow: hidden;
        &:not(:last-child) {
            margin: 0 0 46px;
        }
    }

    form {
      position: relative;

      .form_select {
        position: relative;
        width: 100%;
        border: none;
        outline: none;
        background-color: var(--light-grey);
        border-radius: 50px;
        padding: 20px 30px;
        color: #817E7E;
        font-family: "Raleway";
        font-size: 18px;
        font-weight: 400;
        line-height: calc(22 / 18);
        cursor: pointer;

        option {
          cursor: pointer;
          
          &:checked {
            background-color: #838383;
            color: #fff;
          }
        }
      }
    }

    .success_message {
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translate(-50%, 0);

      color: #48903A;
      font-size: 20px;
      font-weight: 500;
    }
    .error_message {
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translate(-50%, 0);

      color: #E11F1F;
      font-size: 20px;
      font-weight: 500;
    }
}

.form_body {
  background-color: var(--light-grey);
  display: grid;
  grid-template-columns: 1fr 522px;
  gap: 22px;
  padding: 70px;

  @media (max-width: 1600px) {
    padding: 50px;
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 1440px) {
    grid-template-columns: 1fr; 
  }
  @media (max-width: 1200px) {
    padding: 20px;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr; 
  }
  @media (max-width: 480px) {
    padding: 10px; 
  }

  .column {
    background-color: var(--white);
    border-radius: 35px;
    display: grid;
    align-content: start;
    gap: 32px;
    padding: 50px;

    @media (max-width: 1440px) {
      padding: 35px;
    } 
    @media (max-width: 480px) {
      padding: 30px 16px; 
    }

    &.left_column {
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      hr {
        @media (min-width: 768px) {
          grid-column: 1/3;
        }
      }
      .column_title {
        @media (min-width: 768px) {
          grid-column: 1/3;
        }
      }
    }

    hr {
      height: 2px;
      background-color: var(--light-grey);
      border: none;
    }

    .column_title {
      font-size: 32px;
      font-weight: 500;
    }

  }

  .school_wrapper {

    @media (min-width: 768px) {
      grid-column: 1/3;
    }

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    & > div {
      &:nth-child(2) {

        @media (min-width: 640px) {
          width: 155px;
        }
        
        & > div {
          position: relative;
          
          &::before {
            content: '№';
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translate(0, -50%);
            color: #817E7E;
            font-size: 18px;
            font-weight: 500;
          }
        }

        input {
          padding-left: 50px;
        }
      }
    }
  }
}

.confirm_button {
  cursor: pointer;
}

.datepicker_wrapper {
  display: grid;
  align-items: center;

  & > div {
    grid-area: 1/1;
  }

  svg {
    grid-area: 1/1;
    justify-self: end;
    z-index: 1;
    margin-right: 30px;
    pointer-events: none;
  }
}

.datepicker_header {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;

  font-size: 20px;
  font-weight: 600;

  p {
    cursor: pointer;
  }

  button {
    font-size: 20px;
    font-weight: 600;
  }
}

.select_wrapper {
  justify-self: center;
  
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;

  select {
    padding: 2px;
    cursor: pointer;
    
    font-size: 20px;
    font-weight: 600;

    &:nth-child(1) {
      border-radius: 8px 0 0 8px;
    }
    &:nth-child(2) {
      border-radius: 0 8px 8px 0;
    }
  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 20px;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: #000000;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.school_input_wrapper {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 0 10px;

  div {
    grid-column: 1/3;
  }
}

.deleteAccout {
  cursor: pointer;
  color: var(--main-color);
  font-size: 20px;
  padding: 18px;
}
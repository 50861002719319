.card {
  padding: 50px;
  box-shadow: 0px 0px 8px 0px #0000001f;
  border-radius: 50px;
  display: flex;
  column-gap: 46px;

  @media (max-width: 1200px) {
    padding: 30px;
    border-radius: 26px;
    column-gap: 36px;
  }

  @media (max-width: 768px) {
    padding: 32px;
    border-radius: 36px;
    column-gap: 26px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 85px;
  }

  @media (max-width: 568px) {
    padding: 10px;
    border-radius: 26px;
    column-gap: 16px;
    padding-top: 73px;
  }
}

.card_gray {
  background-color: #f8f8f8;
  box-shadow: none;
}

.card_image {
  border-radius: 35px;
  width: 30%;
  img {
    border-radius: 35px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    max-width: 280px;
    height: 190px;
    width: 280px !important;
    border-radius: 26px;

    img {
      border-radius: 26px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100% !important;
    border-radius: 36px;

    img {
      border-radius: 36px;
    }
  }

  @media (max-width: 480px) {
    border-radius: 16px;
    width: 302px;
    height: 132px;

    img {
      border-radius: 26px;
    }
  }
}

.card__right {
  width: 70%;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 32px;
  }
}

.card__right_header {
  h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
    display: flex;
    flex-direction: column-reverse !important;
    align-items: flex-start;
    gap: 16px;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
    > div {
      position: absolute;
      top: 32px;
      left: 32px;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
    }

    > div {
      top: 20px;
      left: 20px;
    }
  }
}

.card__right_content {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: 1200px) {
    padding-top: 0;
    padding-bottom: 0;
    gap: 16px;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  //375

  @media (max-width: 480px) {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.dates {
  display: flex;
  justify-content: flex-start;
  column-gap: 36px;
  margin-top: auto;

  @media (max-width: 1200px) {
    gap: 16px;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    span {
      font-size: 20px;
    }

    > div {
      justify-content: space-between;
      padding: 12px;
    }
  }
}
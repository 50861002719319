.symbols_message {
    background-color: white;
    z-index: 80;
    position: absolute;
    bottom: 85px;
    left: 50%;
    padding: 14px 24px 14px 24px;
    gap: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px #00000014;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #2A2A2A;
}

.post__commentsForm {
    textarea {

        background-color: #F8F8F8;
        border: 2px solid #F2F2F2;
        border-radius: 25px;
        color: #6f6f6f;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 32px 30px;
        resize: none;
        width: 100%;
        height: 220px;
    }
    .textarea_wrapper {
        position: relative;
    }
    .submit_button {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
}

.comment {
    border-bottom: 2px solid #F8F8F8;
    padding: 0 0 20px;
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    &__body, &__footer {
      padding-left: 64px;
    }
    &__body {
        p {
            font-size: 20px;
            font-weight: 400;
            line-height: calc(24 / 20);
        }
        &:not(:last-child) {
            margin: 0 0 24px;
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &__likes {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 20px;
        cursor: pointer;
        .comment__label {
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            &_liked {
                color: #E11F1F;
            }
        }
    }
    &__button {
        color: #91172C;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 12px 8px;
    }
}

.author {
    cursor: pointer;
    display: grid;
    gap: 4px 12px;
    &__avatar {
        border-radius: 50%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 56px;
        height: 56px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 22px;
        font-weight: 500;
        line-height: calc(26 / 22);
    }
    &__date {
        color: #9D9D9D;
        align-self: start;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-family: "Saira", "sans-serif";
        font-size: 18px;
        font-weight: 500;
        line-height: calc(22 / 18);
    }
}

.hero {
  background: #91172C;
  // background-image: url();
  &__title {
    background-color: #91172C;
    border: 6px solid #FFFFFF;
    top: calc(89 / 560 * 100%);
    left: calc(46 / 1660 * 100%);
    transform: rotate(-14deg);
    transform-origin: center;
  }
  &__title_2 {
    background-color: #FFB800;
    text-transform: none;
    top: calc(190 / 560 * 100%);
    left: calc(160 / 1660 * 100%);
    transform: rotate(-14deg);
  }
  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: screen;
  }
  &__ruby {
    right: calc(80 / 1660 * 100%);
    bottom: 5px;
  }
}

.programs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.programs_menuWrapper {

}

.programs_menu {
  width: 70%;
  margin: 0 auto;
  background: #F8F8F8;
  padding: 8px;
  border-radius: 50px;
  display: flex;
  column-gap: 8px;
}

.programs_menu_item {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #2A2A2A;
  background-color: white;
  border-radius: 50px;
  font-family: 'Saira', sans-serif;
  padding: 18px;
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1.5vw;
  text-align: center;
}

.programs_menu_item_active {
  background-color: #91172C;
  color: white;
}

.programs_content {
  padding: 70px;
  border-radius: 50px;
  height: 724px;
  background-color: #51CC39;
  display: flex;
  column-gap: 4vw;
  justify-content: space-between;
}

.programs_content_left {
  width: 60%;
}

.programs_content_up {
  color: white;
  h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 76px;
    text-align: left;
  }
  p {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }
}

.programs_content_buttons {
  display: flex;
  column-gap: 12px;
}

.programs_content_image {
  border-radius: 35px;
  height: 100%;
  width: 40%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 35px;
  }
}
.card {
 background-color: white;
 padding: 28px 26px;
  border-radius: 35px;
 display: flex;
 height: 100%;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 row-gap: 36px;
 &:hover {
  background-color: #F2F2F2;
 }
}

.card_avatar {
 width: 160px;
 height: 160px;
 border-radius: 50%;
 img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
}

.card_up {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: flex-start;
 row-gap: 18px;
}

.card_name {
 display: flex;
 flex-direction: column;
 row-gap: 12px;
 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.011em;
  text-align: center;
 }
}

.level {
 font-family: Raleway;
 font-size: 16px;
 font-weight: 500;
 line-height: 20px;
 letter-spacing: -0.011em;
 text-align: left;
 background-color: #FF9900;
 border-radius: 30px;
 padding: 6px 13px;
 color: white;
 span {
  font-family: Saira;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.011em;
  text-align: left;
 }
}

.card_description {
 font-family: Raleway;
 font-size: 16px;
 font-weight: 400;
 line-height: 20px;
 letter-spacing: -0.011em;
 text-align: left;
}

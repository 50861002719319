.authBlock__form {
  :global(.authBlock__form-group) {
    display: grid;
    justify-content: stretch;

    input {
      &._invalid {
        border: 2px solid #e11f1f;
      }
    }

    span {
      color: #e11f1f;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

.politics {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: start;

  @media (max-width: 600px) {
    margin-top: 10px; 
  }

  span {
    font-size: 16px;
    line-height: 1.2;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    a {
      text-decoration: underline;
      margin-left: 3px;
    }
  }

  .checkbox {
    color: var(--main-color) !important;
  }

  .checkbox_error {
    grid-column: 1/3;
    color: #e11f1f;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.button {
  background-color: var(--main-color);
}
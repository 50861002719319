.choosed_files_wrapper {
    margin-top: 20px;
    gap: 8px;
    display: flex;
    flex-flow: wrap;
}

.error {
    padding: 0 0 20px 0;
    text-align: center;
    color: red;
    font-size: 20px;
}

.fileBlock {
    max-width: 300px;
    padding: 12px 20px;
    background-color: #48903A;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    flex-direction: row !important;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: white;

    img {
        display: block;
    }
}

.feedback {
    &__title {
        color: #000000;
        font-size: 40px;
        font-weight: 500;
        line-height: calc(48 / 40);
        overflow: hidden;
        &:not(:last-child) {
            margin: 0 0 46px;
        }
    }
    :global(.form) {
      @media (max-width: 1440px) {
        border-radius: 35px!important; 
      }
    
    }
}

.form {
    &__body {
        background-color: var(--white);
        display: grid;
        grid-template-columns: 1fr max(480px, calc(648 / 1520 * 100%));
        gap: 60px;
        padding: calc(70 / 1660 * 100%);

        @media (max-width: 1280px) {
          grid-template-columns: 1fr;
        }

        & div:first-child {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
        }

        :global(.dropzone) {
          @media (max-width: 480px) {
            padding: 20px!important; 
          }
        }
    }
    &__footer {
        background-color: var(--light-grey);
    }
    &__control {
        grid-column: 1 / 2;
    }
    @media screen and (max-width: 1280px) {
        &__body {
            // grid-template-columns: 1fr 1fr;
            gap: 40px;
            & > * {
                &:last-child {
                    grid-column: 1/2!important;
                    grid-row: auto !important;
                }
            }
        }
        &__control {
            &:first-child, &:nth-child(2) {
                grid-column: span 1;
            }
            &:nth-child(3) {
                grid-column: span 2;
            }
        }
    }
}

.successPopup {
    &__content {
        border-radius: 50px;
        padding: 70px;
        max-width: 1660px;
    }
    &__body {
        display: flex;
        gap: 60px;
    }
    &__info {
        color: #3E3E3E;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    &__title {
        font-size: 64px;
        font-weight: 600;
        line-height: calc(76 / 64);
    }
    &__text {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
    }
    &__actions {
        display: flex;
        gap: 18px;
        margin-top: auto;
    }
}

.page_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(#F23E5D, #91172C);
  background-image: url('../../images/download-app-pattern.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .text_wrapper {
    position: relative;
    display: grid;
    align-content: center;
    align-items: center;
    gap: 32px;
    padding: 0 0 100px 100px;

    @media (max-width: 1440px) {
      padding: 0 0 50px 50px;
    }
    @media (max-width: 1100px) {
      max-width: 600px;
      padding: 100px 20px;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      width: 80vw;
      height: auto;
      aspect-ratio: 1/1;
      transform: translate(-20vw, 0);
      z-index: 1;

      @media (max-width: 1100px) {
        width: 140vw;
        left: 50%;
        top: 0;
        transform: translate(-50%, -10%);
      }
      @media (max-width: 768px) {
        width: 1200px;
        height: 1200px;
      }
    }

    & > * {
      z-index: 2;
    }

    h1 {
      font-size: 64px;
      line-height: 76px;
      font-weight: 600;

      @media (max-width: 1440px) {
        font-size: 42px;
        line-height: 1.2; 
      }
      @media (max-width: 768px) {
        font-size: 36px; 
      }
    }

    p {
      font-size: 20px;
      line-height: 24px;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 1.4; 
      }
    }

    .btns {
      display: flex;
      gap: 20px;
      margin-top: 48px;

      @media (max-width: 768px) {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
      }
      
      a {
        display: flex;
        align-items: center;
        gap: 12px;
        background-color: var(--main-color);
        padding: 6px 24px 6px 6px;
        border-radius: 60px;
        
        color: var(--white);
        font-size: 20px;
        white-space: nowrap;

        @media (max-width: 1440px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          max-width: 300px;
          width: 100%;

          svg {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }

  .phone_wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 200px;

    @media (max-width: 1440px) {
      padding-right: 100px;
    }
    @media (max-width: 1100px) {
      grid-row: 1/2;
      z-index: 1;
      padding: 50px 0 0 0;
    }

    img {
      width: auto;
      height: 80vh;

      @media (max-width: 1100px) {
        height: 600px;
      }
      @media (max-width: 900px) {
        height: 500px;
      }
      @media (max-width: 480px) {
        height: 350px; 
      }
    }
  }
}
.event {
    border-radius: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    gap: 46px;
    padding: 50px;

    @media (max-width: 1440px) {
      padding: 30px; 
    }
    @media (max-width: 1000px) {
      flex-direction: column; 
    }
    @media (max-width: 768px) {
      padding: 30px 20px; 
    }

    &__image {
        aspect-ratio: calc(420 / 260);
        background-color: #F4F4F4;
        border-radius: 35px;
        flex: 0 0 420px;
        overflow: hidden;

        @media (max-width: 1440px) {
          flex: 0 0 320px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
      width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
        }
    }
    &__title {
        font-size: 32px;
        font-weight: 500;
        line-height: calc(38 / 32);

        @media (max-width: 1440px) {
          font-size: 24px; 
        }
    }
    &__status {
        background-color: #9D9D9D;
        border-radius: 50px;
        color: #FFFFFF;
        flex-basis: 220px;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        letter-spacing: -0.01em;
        padding: 12px 30px;
        text-align: center;

        @media (max-width: 768px) {
          flex-basis: auto; 
        }

        &.active {
          background-color: #FF9900;
        }
    }
    &__info {
        display: flex;
        gap: 20px;

        @media (max-width: 600px) {
          flex-direction: column; 
        }
    }
    &__location, &__date {
        display: flex;
        align-items: center;
        gap: 6px;
        .icon {

        }
        span {
            font-size: 20px;
            line-height: calc(24 / 200);

            @media (max-width: 1440px) {
              font-size: 18px; 
            }
        }
    }
    &__date {
        span {
            font-family: "Saira", "sans-serif";
            font-weight: 500;

            @media (max-width: 1440px) {
              font-size: 18px; 
            }
        }
    }
    &__text {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);

        @media (max-width: 1440px) {
          font-size: 18px; 
        }
    }
    &__sponsor {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: grid;
        align-self: flex-start;
        gap: 4px 16px;
        padding: 6px 34px 6px 6px;
    }
    &__sponsorAvatar {
        border-radius: 50%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 60px;
        height: 60px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__sponsorLabel {
        color: #817E7E;
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 16px;
        font-weight: 500;
        line-height: calc(20 / 16);
    }
    &__sponsorName {
        align-self: start;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &_completed {
        background-color: #F8F8F8;
        .event__status {
            background-color: #9D9D9D;
        }
    }
}
.card {
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    padding: 0 0 32px;
    overflow: hidden;

    @media (max-width: 768px) {
      border-radius: 35px; 
    }

    &__image {
        background-color: #DEDEDE;
        aspect-ratio: calc(540 / 350);

        img {
            width: 100%;
            height: 100%;
            max-height: 365px;
            object-fit: cover;
            vertical-align: top;
        }
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    
    &__content {
        margin: 0 0 auto;
        padding: 0 24px;
    }
    &__participants {
        display: flex;
        align-items: center;
        gap: 6px;
        &:not(:last-child) {
            margin: 0 0 16px;
        }
    }
    &__participantsIcon {
        background-color: #368826;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        @media (max-width: 768px) {
          width: 28px;
          height: 28px;
        }

        svg {
            width: 20px;
            height: 20px;

            @media (max-width: 768px) {
              width: 16px;
              height: 16px;
            }
        }
    }
    &__participantsLabel {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;

        @media (max-width: 768px) {
          font-size: 16px; 
        }

        span {
            font-family: "Saira", "sans-serif";
        }
    }
    &__title {
        font-size: 28px;
        font-weight: 600;
        line-height: calc(32 / 28);

        @media (max-width: 768px) {
          font-size: 22px; 
        }

        &:not(:last-child) {
            margin: 0 0 12px;
        }
    }
    &__desc {
        font-size: 20px;
        font-weight: 400;
        line-height: calc(24 / 20);
        max-height: calc(1em * 1.2 * 4);
        overflow: hidden;

        @media (max-width: 768px) {
          font-size: 18px; 
        }
    }
    &__button {
        cursor: pointer;
        padding-left: 50px;
        padding-right: 50px;
        &:not(:first-child) {
            margin: 36px auto 0;
        }
    }
}
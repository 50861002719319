.hero {
    background-color: #FFB800;
    // background-image: url();

    @media (max-width: 1440px) {
      height: 480px; 
    }
    @media (max-width: 1000px) {
      height: 420px; 
    }
    @media (max-width: 768px) {
      height: 360px; 
    }
    @media (max-width: 480px) {
      height: 320px; 
    }

    &__title {
        background-color: #FF7A00;
        top: calc(100 / 560 * 100%);
        left: calc(45 / 1660 * 100%);
        transform: rotate(-13deg);
        transform-origin: center;

        @media (max-width: 1440px) {
          font-size: 30px; 
        }
        @media (max-width: 768px) {
          font-size: 22px; 
        }
    }
    &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: soft-light;
    }
    &__ruby {
        right: calc(20 / 1660 * 100%);
        bottom: 0;

        @media (max-width: 1440px) {
          width: 500px; 
        }
        @media (max-width: 768px) {
          width: 70%;
        }
    }
}

.selection {
    &__body {
        background-color: #FFFFFF;
        border-radius: 50px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        gap: 100px;
        padding: 70px;

        @media (max-width: 1440px) {
          padding: 50px;
        }
        @media (max-width: 1200px) {
          gap: 50px;
        }
        @media (max-width: 1000px) {
            flex-direction: column-reverse;
            padding: 36px;
            border-radius: 36px;
        }
        @media (max-width: 375px) {
            padding: 18px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 16px;
    }
    &__title {
        font-size: 36px;
        font-weight: 600;
        line-height: calc(44 / 36);

        @media (max-width: 1440px) {
          font-size: 32px;
        }
        @media (max-width: 1000px) {
          font-size: 28px;
        }
    }
    &__desc {
        font-size: 24px;
        font-weight: 400;
        line-height: calc(28 / 24);

        @media (max-width: 1440px) {
          font-size: 18px;
        }
        @media (max-width: 1000px) {
          font-size: 16px;
        }
    }
    &__contacts {
        margin-top: auto;
    }
    &__contactsPhone {
        font-family: "Saira", "sans-serif";
        font-size: 32px;
        font-weight: 500;
        line-height: calc(38 / 32);

        @media (max-width: 1440px) {
          font-size: 24px;
        }
        @media (max-width: 1000px) {
          font-size: 20px;
        }

        &:not(:last-child) {
            margin: 0 0 12px;
        }
    }
    &__contactsPerson {
        font-size: 28px;
        font-weight: 500;
        line-height: calc(32 / 28);

        @media (max-width: 1440px) {
          font-size: 20px;
        }
        @media (max-width: 1000px) {
          font-size: 18px;
        }
    }
    &__image {
        border-radius: 35px;
        flex: 0 0 639px;
        height: 584px;
        overflow: hidden;

        @media (max-width: 1440px) {
          height: 440px;
          width: 440px;
          flex: none;
        }
        @media (max-width: 1200px) {
          width: 320px;
          height: 320px;
        }
        @media (max-width: 900px) {
          width: 280px;
          height: 280px;
        }
        @media (max-width: 1000px) {
          width: 100%;
          height: auto;
          max-height: 344px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (max-width: 1000px) {
              height: auto;
             object-fit: contain;
            }
        }
    }
}

.views {
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
        }

        & > div {
            flex: 0 0 calc((100% - 2 * 20px) / 3);
        }
    }
}

.view {
    background-color: #91172C;
    border: 3px solid #91172C;
    border-radius: 35px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px;
    height: 332px;
    transition: background-color 0.3s ease 0s, border-width 0.3s ease 0s, color 0.3s ease 0s;

    @media (max-width: 1440px) {
      height: 250px;
    }
    @media (max-width: 1000px) {
      padding: 24px;
    }

    &__age {
        // font-family: "Saira", "sans-serif";
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        margin: 0 0 auto;

        @media (max-width: 1440px) {
          font-size: 18px;
        }
        @media (max-width: 1200px) {
          font-size: 16px;
        }
    };
    &__phone {
        font-family: "Saira", "sans-serif";
        font-size: 32px;
        font-weight: 500;
        line-height: calc(38 / 32);

        @media (max-width: 1440px) {
          font-size: 24px;
        }
        @media (max-width: 1200px) {
          font-size: 20px;
        }
    }
    &__coach {
        font-size: 28px;
        font-weight: 500;
        line-height: calc(32 / 28);

        @media (max-width: 1440px) {
          font-size: 20px;
        }
        @media (max-width: 1200px) {
          font-size: 16px;
        }
    }
    @media screen and (any-hover: hover) {
        &:hover {
            background-color: #FFFFFF;
            border-width: 2px;
            color: #91172C;
        }
    }
}

.footnote {
    &__content {
        border-radius: 50px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        padding: 70px;

        @media (max-width: 1440px) {
          padding: 50px;
        }
    }
    &__title {
        font-size: 36px;
        font-weight: 600;
        line-height: calc(44 / 36);

        @media (max-width: 1440px) {
          font-size: 32px;
        }
        @media (max-width: 1200px) {
          font-size: 28px;
        }

        &:not(:last-child) {
            margin: 0 0 16px;
        }
    }
    &__desc {
        font-size: 24px;
        font-weight: 400;
        line-height: calc(28 / 24);

        @media (max-width: 1440px) {
          font-size: 18px;
        }
        @media (max-width: 1200px) {
          font-size: 16px;
        }
    }
}
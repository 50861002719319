.photoGallery {
    &__header {
        &:not(:last-child) {
            margin: 0 0 100px;
        }
    }
    &__title {
        font-size: 40px;
        font-weight: 500;
        line-height: calc(48 / 40);
        &:not(:last-child) {
            margin: 0 0 46px;
        }

    }
    &__info {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    &__date {
        background-color: #FBFBFB;
        border-radius: 50px;
        font-family: "Saira", "sans-serif";
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &__tag {
        color: #91172C;
        font-size: 32px;
        font-weight: 500;
        line-height: calc(38 / 32);
        margin-right: auto;
    }
    &__qty {
        background-color: #FBFBFB;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 6px;

    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
    &__photoCard {
        border-radius: 35px;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        cursor: pointer;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    }
    @media screen and (max-width: 992px) {
        &__grid {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media screen and (max-width: 576px) {
        &__grid {
            grid-template-columns: 1fr;
        }
        &__photoCard {
            justify-self: center;
            max-width: 480px;
        }
    }
}

.photogallery_modal {
  padding: 0;
  // @media (max-width: 1400px) {
  //   padding: 0;
  // }

  .photogallery_modal_body {
    position: relative;
    background-color: transparent!important;
    max-width: 80vw;
    
    @media (max-width: 1400px) {
      display: flex;
      align-items: center;
      height: 100vh;
      top: auto;
      transform: translate(-50%, 0);
    }
    @media (max-width: 600px) {
      max-width: none;
    }

    & > div {
      & > div {
        align-items: center;
      }
    }
  
    & > button {
      background-color: var(--black)!important;

      @media (max-width: 768px) {
        top: 10px!important;
        transform: none!important; 
      }

      &::before, &::after {
        background-color: var(--white)!important;
      }
    }
  }

  :global(.swiper-button-prev), :global(.swiper-button-next) {
    background-color: var(--black);
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @media (max-width: 600px) {
      width: 42px;
      height: 42px;
      opacity: .6;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background-color: var(--white);

      @media (max-width: 600px) {
        width: 10px;
      }
    }
  }

  :global(.swiper-button-prev) {
    left: -10px;
    transform: translate(-100%, 0);

    @media (max-width: 768px) {
      transform: translate(-50%, 0);
    }
    @media (max-width: 600px) {
      left: 10px;
      transform: translate(0%, 0);
    }

    &::before {
      transform: translate(0, 220%) rotate(45deg);

      @media (max-width: 600px) {
        transform: translate(0, 140%) rotate(45deg);
      }
    }
    &::after {
      transform: translate(0, -220%) rotate(-45deg);

      @media (max-width: 600px) {
        transform: translate(0, -140%) rotate(-45deg);
      }
    }
  }

  :global(.swiper-button-next) {
    right: -10px;
    transform: translate(100%, 0);

    @media (max-width: 768px) {
      transform: translate(50%, 0);
    }
    @media (max-width: 600px) {
      right: 10px;
      transform: translate(0%, 0);
    }

    &::before {
      transform: translate(0, -220%) rotate(45deg);

      @media (max-width: 600px) {
        transform: translate(0, -140%) rotate(45deg);
      }
    }
    &::after {
      transform: translate(0, 220%) rotate(-45deg);

      @media (max-width: 600px) {
        transform: translate(0, 140%) rotate(-45deg);
      }
    }
  }

  :global(.swiper-pagination) {
    width: max-content;
    background-color: var(--black);
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    padding: 12px 30px;
    border-radius: 50px;

    color: var(--white);
    font-size: 20px;
    font-family: "Saira", "sans-serif";
    font-weight: 500;

    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }
}

.photogallery_slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  
  opacity: 0!important;
  
  @media (max-width: 1400px) {
    height: max-content;
  }
  
  &:global(.swiper-slide-visible) {
    opacity: 1!important;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // border-radius: 35px;
  }
}


.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 100;
  overflow-y: auto;
  padding: 100px 0;

  animation: show .2s ease-in-out forwards;

  &:global(._hide) {
    animation: hide .2s ease-in-out forwards;
  }

  .modal_body {
    max-width: 1100px;
    width: 100%;
    position: relative;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    background-color: var(--white);

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -20px;
      top: 0px;
      width: 50px;
      height: 50px;
      padding: 0;

      transform: translate(100%, 0);
      background-color: #fff;
      border-radius: 50%;

      cursor: pointer;

      @media (max-width: 768px) {
        right: 0px;
        top: -10px;
        transform: translate(0, -100%); 
      }
      @media (max-width: 600px) {
        width: 42px;
        height: 42px;
        right: 10px;
      }

      &::before, &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background-color: #000;

        @media (max-width: 600px) {
          width: 10px;
        }
      }
      &::before {
          transform: rotate(45deg);
      }
      &::after {
          transform: rotate(-45deg);
      }
    }

  }
}

@keyframes show {
  0% {
     opacity: 0;
  }
  100% {
     opacity: 1;
  }
}
@keyframes hide {
  0% {
     opacity: 1;
  }
  100% {
     opacity: 0;
  }
}


.wrapper {
  margin-top: 60px;
}

.filterBlock {
  display: flex;
  justify-content: space-between;
  column-gap: 4vw;

  @media (max-width: 1600px) {
    .image {
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    row-gap: 40px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 50px;
  }
  @media (max-width: 768px) {
    padding: 30px 20px;
    border-radius: 35px;
  }

  .image {
    width: 50%;
    background-color: #FFB800;
    border-radius: 26px;
    padding: 15px;

    @media (max-width: 1200px) {
      width: 100%; 
      max-height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;

      @media (max-width: 1200px) {
        height: 100%;
        width: auto;
      }
    }
  }
}

.form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; 
  }

  h2 {
    grid-column: 1/3;

    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-align: left;

    @media (max-width: 768px) {
      grid-column: auto;
      font-size: 28px; 
    }
  }
}

.form_inputField {
  display: flex;
  column-gap: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
  }
}

.input {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;

  .selectBar {
    min-width: 100%;
    position: relative;

    &::before, &::after {
      content: '';
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
      width: 9px;
      height: 2px;
      background-color: #2A2A2A;
    }
    &::before {
      transform: translate(3px, 0) rotate(-45deg);
    }
    &::after {
      transform: translate(-3px, 0) rotate(45deg);
    }

    select {
      -webkit-appearance: none; /* Для Safari */
      -moz-appearance: none;    /* Для Firefox */
      background-size: contain;
      appearance: none;
      width: 100%;
      padding: 19px 30px;
      background-color: #F8F8F8;
      border-radius: 50px;
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.011em;
      cursor: pointer;

      color: #6F6F6F;
    }

  }

  .searchBar {
    min-width: 100%;
    position: relative;

    input {
      width: 100%;
      padding: 19px 64px;
      color: #6F6F6F;
      background-color: #F8F8F8;
      border-radius: 50px;
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.011em;

      @media (max-width: 768px) {
        font-size: 16px;
        padding: 19px 10px 19px 42px;
      }
    }

    select {
      -webkit-appearance: none; /* Для Safari */
      -moz-appearance: none;    /* Для Firefox */
      background-size: contain;
      appearance: none;
      width: 100%;
      color: #2A2A2A;
      padding: 19px 30px;
      background-color: #EEEEEE;
      border-radius: 50px;
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.011em;
    }

    img {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;

      @media (max-width: 768px) {
        left: 10px; 
      }
    }
  }

  label {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    @media (max-width: 768px) {
      font-size: 18px; 
    }
  }
}

.actionButton {
  margin-right: auto;
  margin-top: 40px;
  background-color: var(--main-color);

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 18px;
  }
}


.people_wrapper {
  display: grid;
  gap: 46px;

  .section_header {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr;

    @media (max-width: 768px) {
      grid-template-columns: auto auto;
      justify-content: start;
      gap: 20px;
    }
  }

  .users_sort {
    justify-self: end;

    position: relative;
    width: max-content;
    min-width: 250px;

    @media (max-width: 768px) {
      grid-column: 1/3;
      justify-self: start;
    }

    .value {
      background-color: var(--white);
      border-radius: 35px;
      padding: 18px 30px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
      cursor: pointer;

      color: var(--black);
      font-size: 18px;
      font-weight: 500;

      &._active {
        &::before {
          transform: translate(3px, 0) rotate(45deg);
        }
        &::after {
          transform: translate(-3px, 0) rotate(-45deg);
        }

        & + div {
          opacity: 1;
          visibility: visible;
        }
      }

      &::before, &::after {
        content: '';
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
        width: 9px;
        height: 2px;
        background-color: #2A2A2A;
      }
      &::before {
        transform: translate(3px, 0) rotate(-45deg);
      }
      &::after {
        transform: translate(-3px, 0) rotate(45deg);
      }

    }

    .select {
      position: absolute;
      bottom: -12px;
      transform: translate(0, 100%);
      width: 100%;
      overflow: hidden;
      background-color: var(--white);
      border-radius: 35px;
      padding: 18px 0;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;

      & > div {
        padding: 20px 30px;
        cursor: pointer;

        color: var(--black);
        font-size: 18px;
        font-weight: 500;

        transition: all .3s ease;
        
        &._active {
          background-color: #F8F8F8;
        }

        &:hover {
          background-color: #F8F8F8;
        }
      }
    }
  }
}
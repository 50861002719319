.news {
    &__innerWrapper {
        position: relative;

        @media (max-width: 1440px) {
          padding-top: 80px; 
        }
    }
    &__cover {
        margin: 30px auto 0;
        border-radius: 50px;
        overflow: hidden;
        width: 60%;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 46px;
        margin: 0 auto;
        width: 100%;
        max-width: 1100px;

        @media (max-width: 768px) {
          gap: 30px; 
        }
    }
    &__title {
        font-size: 64px;
        font-weight: 600;
        line-height: calc(76 / 64);

        @media (max-width: 1440px) {
          font-size: 42px; 
        }
        @media (max-width: 768px) {
          font-size: 32px; 
        }

    }
    &__subheader {
        display: flex;
        gap: 12px;
    }
    &__text {
        a {
            color: var(--main-color);
        }
        p {
            font-size: 24px;
            font-weight: 400;
            line-height: calc(32 / 24);
            &:not(:last-child) {
                margin: 0 0 40px;
            }

            @media (max-width: 768px) {
              font-size: 18px; 
            }
        }

        ul {
            list-style-type: disc; /* Добавит точки */
            margin-left: 20px; /* Отступ для списка */
            font-size: 24px;
            line-height: calc(32 / 24);

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }

        li {
            list-style: disc !important;
            margin-bottom: 10px; /* Отступ между элементами списка */
        }
    }
    &__footer {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 6px;
        & > * {
            &:last-child {
                margin-left: auto;
            }
        }
        [class*="tag"] {
            background-color: #FFFFFF;
        }
    }
    &__author {
        background-color: #FFFFFF;
        border-radius: 50px;
        display: grid;
        flex-shrink: 0;
        gap: 16px;
        padding: 6px 34px 6px 0;
    }
    &__authorAvatar {
        border-radius: 50%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 48px;
        height: 48px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__authorName {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        color: #817E7E;
        font-size: 16px;
        font-weight: 500;
        line-height: calc(20 / 16);
    }
    &__authorPosition {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &__actions {
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: absolute;
        top: calc(328 / 1218 * 100%);
        left: 0;

        @media (max-width: 1440px) {
          display: none;
        }
    }
    &__favorite, &__share {
        background-color: #2A2A2A;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        cursor: pointer;
    }
}

.moreNews {
    &__body {
        display: flex;
        flex-direction: column;
        gap: 46px;
    }
    &__row {
        display: flex;
        gap: 20px;
        & > * {
            flex: 0 0 calc((100% - 3 * 20px) / 4);
        }
    }
    &__controls {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        
        button {
          @media (max-width: 480px) {
            span {
              display: none;
            } 
          }
        }

        & > * {
            &:disabled {
                background-color: rgba(180, 180, 180, 0.65);
                cursor: auto;
            }
            &:first-child {
                flex-direction: row-reverse;
                & > img {
                    transform: rotate(-180deg);
                }
            }
        }

    }
    &__more {
        align-self: flex-end;
    }
}

.tag {
    background-color: #F8F8F8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    cursor: pointer;
    &__label {
        font-family: "Saira", "sans-serif";
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        &_liked {
            color: #E11F1F;
        }
    }
}

.eventPage_Deck {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
}

.eventPage_regularHeader {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
}

.eventPage_gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.eventPage_gallery_photo {
    max-width: 300px;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.photogallery_modal {
    z-index: 1000;

    @media (max-width: 1400px) {
        padding: 0;
    }

    .photogallery_modal_body {
        position: relative;
        background-color: transparent!important;

        @media (max-width: 1400px) {
            max-width: 80vw;
            height: 100vh;
            top: auto;
            transform: translate(-50%, 0);
        }

        & > button {
            background-color: var(--black)!important;

            &::before, &::after {
                background-color: var(--white)!important;
            }
        }
    }
}

.photogallery_slide {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    // opacity: 0!important;

    @media (max-width: 1400px) {
        height: 100vh;
    }

    &:global(.swiper-slide-visible) {
        opacity: 1!important;
    }

    img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
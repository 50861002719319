.contestPage {
  box-shadow: 0px 0px 8px 0px #0000001f;
  border-radius: 50px;
  margin-top: 60px;
  @media (max-width: 768px) {
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    border-radius: 20px;
    width: calc(100% + 40px);
    margin-left: -20px !important;
  }
}

.whitePart {
  padding: 70px;
  @media (max-width: 1200px) {
    padding: 50px;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
}

.grayPart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 46px;
  padding-bottom: 46px;
  background-color: #f8f8f8;
  border-radius: 0 0 50px 50px;

  @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 480px) {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 0 0 20px 20px;
  }
}

.card {
  display: flex;
  column-gap: 32px;

  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
    padding-top: 60px;
    border-radius: 36px;
  }
}

.card__image {
  border-radius: 28px;
  width: 35%;
  img {
    border-radius: 28px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 250px;
    object-position: top;
  }

  @media (max-width: 768px) {
    height: 180px;
  }
}

.card__right {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 20px 0;
  }
}

.card__right_header {
  h3 {
    font-weight: 600;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    text-align: left;

    @media (max-width: 1200px) {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }

  > div {
    @media (max-width: 1200px) {
      position: absolute;
      top: 0;
    }
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.card__right_footer {
  display: flex;
  column-gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.boxShadow {
  width: 100%;
  border: 3px solid #f8f8f8;
  padding: 29px;
  border-radius: 28px;
}

.datesBlock {
  column-gap: 40px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }

  @media (max-width: 480px) {
    padding: 15px;
    border-radius: 16px;
  }
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 480px) {
    > :nth-child(2) {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.011em;
      padding: 8px 20px !important;
    }
  }
  &_text {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;

    @media (max-width: 968px) {
      font-size: 20px;
      line-height: 24px;
      white-space: wrap;
    }

    @media (max-width: 480px) {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      max-width: 125px;
      inline-size: 125px;
    }
  }
}
.grayBlocks {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.grayBlock {
  display: flex;
  column-gap: 20px;
  padding: 36px 50px;
  border-radius: 35px;
  background-color: #fbfbfb;

  @media (max-width: 480px) {
    padding: 16px !important;
  }
}

.grayBlock_text {
  & > h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;

    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  & > p {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    //word-break: break-all;
    width: calc(100% + 40px);

    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 26px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-left: -24px;
    }
  }
}

.results {
  margin-top: 160px;
}

.prizes_content {
  flex-direction: column;
  row-gap: 28px;
  &_block {
    padding: 50px;
    background-color: #fbfbfb;
    border-radius: 50px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    &_place {
      font-family: Saira;
      font-size: 64px;
      font-weight: 600;
      line-height: 76px;
      text-align: center;
      color: white;
      width: 116px;
      height: 116px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 28px;
    }
    &_avatar {
      width: 116px;
      height: 116px;
      overflow: hidden;
      border-radius: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &_info {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      &_name {
        font-family: Raleway;
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
      }
      &_level {
        display: flex;
        justify-content: flex-start;
        column-gap: 28px;
        div {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          span {
            font-family: Saira;
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: 856px) {
    flex-direction: column !important;
    justify-content: space-between !important;

    > a {
      width: 100%;
    }

    &_block {
      padding: 30px;
      row-gap: 20px;
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      &_place {
        font-size: 48px;
        line-height: 56px;
        width: 76px;
        height: 76px;
      }
      &_left {
        row-gap: 20px;
      }
      &_avatar {
        width: 76px;
        height: 76px;
      }
      &_info {
        &_name {
          font-size: 24px;
          line-height: 28px;
        }
        &_level {
          column-gap: 16px;
          div {
            font-size: 18px;
            line-height: 22px;
            span {
              font-size: 24px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    &_block {
      padding: 10px;
      row-gap: 10px;
      border-radius: 16px;
      position: relative;
      &_place {
        font-size: 32px;
        line-height: 38px;
        height: 114px;
        width: calc(100% - 20px);
        border-radius: 8px;
        position: absolute;
        z-index: 0;
        top: 10px;
        left: 10px;
        padding-left: 100px;
      }
      &_left {
        row-gap: 10px;
        flex-direction: column;
        align-items: flex-start;
      }
      &_avatar {
        width: 114px;
        height: 114px;
        border-radius: 8px;
        z-index: 1;
      }
      &_info {
        &_name {
          font-size: 18px;
          line-height: 22px;

          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
        &_level {
          column-gap: 10px;
          div {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            span {
              font-size: 20px;
              line-height: 24px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.results_content {
  display: flex;
  justify-content: space-between;
  column-gap: 4vw;

  > div > img {
    @media (max-width: 1200px) {
      width: 340px;
      height: 360px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
    width: calc(100% + 40px);
    margin-left: -20px !important;
    border-radius: 26px;
  }
}

.results_content_leftPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 76px;
    text-align: left;
    color: #3e3e3e;
  }

  @media (max-width: 1440px) {
    h1 {
      font-size: 48px;
      line-height: 56px;
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 36px;
      line-height: 44px;
    }
  }

  @media (max-width: 900px) {
    h1 {
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 586px) {
    width: 100%;
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.timerBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 32px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #be233a;
  }

  @media (max-width: 1440px) {
    row-gap: 16px;
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.timerBlock_timer {
  display: flex;
  gap: 54px;
  align-items: center;

  @media (max-width: 1440px) {
    gap: 16px;
  }

  @media (max-width: 1200px) {
    gap: 12px;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
}

.timerSeparator {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.timerSeparator::after,
.timerSeparator::before {
  background-color: #be233a;
  border-radius: 50%;
  content: "";
  display: block;
  width: 14px;
  height: 14px;

  @media (max-width: 1440px) {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }
}

.timerItem {
  border: 3px solid #f2f2f2;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 24px 20px;

  @media (max-width: 1440px) {
    padding: 16px;
  }

  @media (max-width: 1200px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    border-radius: 26px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    border-radius: 16px;
  }
}
.timerItem_value {
  background-color: #be233a;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Saira", "sans-serif";
  font-size: 96px;
  font-weight: 500;
  line-height: calc(116 / 96);
  letter-spacing: -0.01em;
  width: 180px;
  height: 180px;

  @media (max-width: 1440px) {
    font-size: 64px;
    line-height: 76px;
    width: 136px;
    height: 136px;
  }

  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
    width: 100px;
    height: 100px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 38px;
    width: 72px;
    height: 72px;

    border-radius: 8px;
  }
}
.timerItem_label {
  font-size: 20px;
  font-weight: 500;
  line-height: calc(24 / 20);
}
.results_content_rightPart {
}

.participants {
  margin-top: 160px;
}

.participants_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  height: 1000px;
  border-radius: 50px;
  align-items: stretch;
  padding: 80px 5%;
  background: url("../../images/activeUsers-bg.svg");

  @media (max-width: 1440px) {
    padding: 50px;
  }

  @media (max-width: 1280px) {
    padding: 30px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 1024px) {
    padding: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (max-width: 768px) {
    padding: 20px;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    justify-content: space-between;

    > a {
      margin: 0;

      > div {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 568px) {
    width: calc(100% + 40px);
    margin-left: -20px !important;
    padding: 10px;
    border-radius: 26px;

    a {
      > div {
        padding: 10px;
        border-radius: 16px;

        img {
          aspect-ratio: 1/1;
          border-radius: 8px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
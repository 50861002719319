.card {
  height: 100%;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  padding: 0 0 32px;
  position: relative;
  overflow: hidden;
  &__image {
    background-color: #dedede;
    aspect-ratio: calc(540 / 350);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
    &:not(:last-child) {
      margin: 0 0 20px;
    }
  }
  &__date {
    background-color: rgba($color: #686868, $alpha: 0.6);
    backdrop-filter: blur(12px);
    border-radius: 50px;
    color: #ffffff;
    font-family: "Saira", "sans-serif";
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
    letter-spacing: -0.01em;
    padding: 6px 14px;
    position: absolute;
    top: 26px;
    left: 26px;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }

    span {
      font-family: "Raleway", "sans-serif";
    }
  }

  &__content {
    margin: 0 0 auto;
    padding: 0 24px;
  }
  &__amount {
    display: flex;
    align-items: center;
    gap: 6px;
    &:not(:last-child) {
      margin: 0 0 16px;
    }
  }
  &__amountIcon {
    background-color: #91172c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__amountLabel {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    span {
      font-family: "Saira", "sans-serif";
    }
  }
  &__title {
    font-size: 28px;
    font-weight: 600;
    line-height: calc(32 / 28);

    @media (max-width: 1440px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin: 0 0 12px;
    }
  }
  &__category {
    font-size: 20px;
    font-weight: 400;
    line-height: calc(24 / 20);
    max-height: calc(1em * 1.2 * 4);
    overflow: hidden;

    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 375px) {
      font-size: 12px;
    }
  }
  @media (max-width: 375px) {
    border-radius: 20px;
  }
}

.image_wrapper {
  position: relative;
  display: grid;
  border-radius: 50px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 128px;
    background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.7));
  }

  img {
    grid-area: 1/1;
    
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  
  p {
    grid-area: 1/1;
    align-self: end;

    z-index: 1;
    padding: 32px;

    color: var(--white);
    font-size: 28px;
  }
}
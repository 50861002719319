/* Chat.css */
.chat-app {
    display: flex;
    height: 100vh;
    column-gap: 20px;
   /*background-color: #f0f2f5;*/
    margin-top: 60px;
    margin-bottom: 200px;
    font-family: 'Arial', sans-serif;

    @media (max-width: 1000px) {
      display: grid;
      margin-top: 80px;
    }
}

.sidebar, .chat-window {
    border-radius: 50px;
    border: 3px solid #F2F2F2;
    // overflow: hidden;

    @media (max-width: 1440px) {
      border-radius: 24px;
    }
    @media (max-width: 1000px) {
      grid-area: 1/1;
    }
}

.search-bar_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    row-gap: 24px;

    @media (max-width: 1440px) {
      padding: 0 20px; 
    }
}

.search-bar_wrapper h2 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}

.search-bar {
    /*gap: 12px;*/
    border-radius: 50px;
    background: #F8F8F8;
    overflow: hidden;
    position: relative;
}

.search-bar img {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(0, -50%);
}

.search-bar input {
    width: 100%;
    padding: 18px 24px 18px 60px;
    background: #F8F8F8;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.sidebar {
    /*width: 300px;*/
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 50px 0;
    width: 30%;
    background-color: #fff;
    transition: width 0.3s ease;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

    @media (max-width: 1440px) {
      padding: 30px 0;
    }
    @media (max-width: 1000px) {
      width: 100%; 
    }
}

.sidebar.collapsed {
    width: 60px;
}

.toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.contacts-list {
    /*padding: 10px;*/
    overflow-y: auto;
}

.contact-item {
    display: flex;
    align-items: center;
    padding: 10px 50px;
    column-gap: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    @media (max-width: 1440px) {
      padding: 10px 20px; 
    }
}

.contact-item_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: hidden;
}

.contact-item_content span:first-child {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.contact-item_content span:last-child {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.contact-item.selected {
    background-color: #F8F8F8;
}

.contact-item:hover {
    background-color: #F8F8F8;
}

.contact-item .status {
    margin-left: auto;
    font-size: 12px;
}

.unread-count {
    background-color: #ff4500;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    margin-left: 10px;
    font-size: 12px;
}

.chat-window {
  position: relative;
    padding-bottom: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: -1px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 9;

    @media (max-width: 1000px) {
      opacity: 0;
      pointer-events: none;
    }

    &._active {
      opacity: 1;
      pointer-events: all;
    }

    .back_button {
      display: none;
      position: absolute;
      top: -15px;
      transform: translate(0, -100%);
      background-color: var(--main-color);
      color: #fff;

      @media (max-width: 1000px) {
        display: block; 
      }
    }
}

.chat-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.message-input {
    padding: 0 50px;
    display: flex;
    column-gap: 20px;

    @media (max-width: 768px) {
      padding: 0 20px;
      column-gap: 10px;
    }
    @media (max-width: 480px) {
      flex-direction: column; 
      row-gap: 10px;
    }

    & > div:first-child {
      position: relative;
      width: 100%;

      span {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);

        cursor: pointer;
        font-size: 22px;

        @media (max-width: 480px) {
          right: 6px; 
        }
      }
    }

    & > button {
      svg {
        display: none;
      }
      @media (max-width: 768px) {
        padding: 18px;

        svg {
          display: block;
        }
        span {
          display: none;
        }
      }
      @media (max-width: 480px) {
        width: 100%;
        svg {
          display: none;
        }
        span {
          display: block;
        }
      }
    }
}

.message-input input {
    flex: 1;
    padding: 20px 60px 20px 30px;
    gap: 12px;
    border: 2px solid #F2F2F2;
    border-radius: 50px;
    width: 100%;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @media (max-width: 1440px) {
      font-size: 18px;
      padding: 17px 60px 17px 30px;
    }
    @media (max-width: 480px) {
      padding: 17px 30px 17px 30px;
    }
}

.message-input input:focus {
    border: 2px solid #91172C;
}

.messages-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 50px;
    row-gap: 20px;
    max-height: calc(100vh - 150px);
    @media (max-width: 768px) {
      padding: 20px; 
    }
}

.message-item {
    display: flex;
    position: relative;
    column-gap: 14px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-end;
    }
}

.message-date {
    font-family: Saira;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #817E7E;

    @media (max-width: 600px) {
      margin-right: 60px; 
    }
}

.message-content_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    /*position: relative;*/
    /*right: 50px;*/

    @media (max-width: 1440px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
      align-items: flex-end;
    }
}

.message-content {
    background-color: #f0f0f0;
    padding: 15px;
    /*border-radius: 25px 25px 25px 0;*/
    margin-bottom: 10px;
}

.messenger-avatar {
    margin-top: auto;
    /*margin-bottom: auto;*/
    overflow: hidden;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    @media (max-width: 600px) {
      position: absolute;
      bottom: -25px;
    }
}

.messenger-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.message-text {
    word-break: break-word;
}

/* Примерные стили для EmojiPicker (если вы используете стороннюю библиотеку, нужно подстроить стили) */
.emoji-picker-react {
    position: absolute;
    bottom: 60px;
    right: 20px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
}

.no-contact-selected {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 32px;

    @media (max-width: 1000px) {
      display: none; 
    }
}

.no-contact_text {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    text-align: center;
}

.no-contact_text h1 {
    font-family: Raleway;
    font-size: 64px;
    font-weight: 500;
    line-height: 76px;
    text-align: center;
}

.no-contact_text h2 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.no-contact_image {
    width: 60%;
    height: auto;
}

.no-contact_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.emoji-picker {
    position: absolute;
    bottom: 70px; /* Расположить окно над полем ввода */
    left: 50%;
    transform: translateX(-20%);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    @media (max-width: 480px) {
      transform: translateX(-50%);
    }
}
.videoAttachment {
    position: absolute;
    z-index: 99;
    border-radius: 16px;
    background: var(--Main-color, #91172C);
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    top: 70px;
    h2 {
        color: var(--White, var(--111, #FFF));
        text-align: center;
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
    }
    input {
        width: 100%;
        border-radius: 50px;
        background: var(--Light-gray, #F8F8F8);
        display: flex;
        padding: 19px 30px;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        color: #817E7E;
        //text-align: center;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
    }
}

.pagination {
    margin-top: 20px;
    column-gap: 20px;
    display: flex;
    align-items: center;
    button {
        background-color: #91172C;
        color: white;
    }

    span {
        font-family: Saira;
        font-size: 20px;
    }
}

.wall {
    background-color: #F8F8F8;
    border-radius: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 46px 60px;
    padding: 70px;
    position: relative;

    @media (max-width: 1600px) {
      gap: 30px; 
    }
    @media (max-width: 1440px) {
      padding: 50px;
      border-radius: 26px;
    }
    @media (max-width: 1200px) {
      display: grid; 
    }
    @media (max-width: 768px) {
      padding: 30px 20px;
    }
    @media (max-width: 480px) {
      padding: 30px 10px; 
    }

    &__nav {
        flex-basis: 100%;
        display: flex;
        gap: 46px;

        @media (max-width: 768px) {
          gap: 20px; 
        }
    }
    &__navButton {
        color: #9D9D9D;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 0 0 20px;
        position: relative;
        &::after {
            background-color: #91172C;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 0;
        }
    }
    &__navButtonActive {
        color: #91172C;
        &::after {
            width: 100%;
        }
    }
    &__content {
        //flex-basis: calc(100% - 460px - 60px);
        max-width: 65%;
        width: 100%;

        @media (max-width: 1200px) {
          max-width: none; 
        }

        & > p {
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);

            @media (max-width: 480px) {
              padding: 0 10px; 
            }
        }
    }
    &__feed {
        & > * {
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }
    &__feedForm {
        border: 2px solid #F2F2F2;
      background-color: #FFFFFF;
      border-radius: 35px;
      padding: 32px;

      @media (max-width: 768px) {
        padding: 16px; 
      }
      @media (max-width: 480px) {
        padding: 10px;
        width: calc(100% - 5px);
      }
      
        .textarea_wrapper {
            border: 2px solid #F2F2F2;
            display: flex;
            flex-direction: column; /* Изменено на флекс вертикально */
            position: relative;
            //overflow: hidden;
            border-radius: 25px;
            min-height: 182px;
            padding: 20px;
            transition: border-color 0.3s ease;
            @media (max-width: 768px) {
              padding: 0; 
            }
        }

        .textarea_wrapper:focus-within {
            border-color: var(--main-color); /* Цвет границы при фокусе */
        }

        .textarea_wrapper_low {
            column-gap: 6px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;

            @media (max-width: 480px) {
              flex-direction: column; 
              overflow: hidden;
            }
        }

        textarea {
            color: var(--black);
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            //padding: 5px;
            width: 100%;
       //     height: 400px;
            resize: none;
            overflow-y: hidden;
            @media (max-width: 480px) {
              font-size: 18px;
            //  padding: 20px 10px;
            margin: 10px;
            }

            &::placeholder {
              color: #6F6F6F;
              font-size: inherit;
              font-weight: inherit;
              line-height: inherit;
            }

            &:focus {
              border-color: var(--main-color);
            }
        }

        .submit_button {
          //  margin-top: 10px; /* Добавляет отступ сверху для кнопки */
            align-self: flex-end;
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            &:last-child {
                margin: 20px 0 0;
            }
        }
    }
    .feenFormFooter {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: start;

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }
    }
    .form_select {
      width: max-content;

      @media (max-width: 480px) {
        font-size: 14px!important; 
      }
    }
    &__feedFormFile {
        margin-top: 20px;
      width: 100%;
      display: flex;
      flex-flow: wrap;
        justify-content: flex-start !important;
      gap: 12px !important;
    }
    &__feedFormFileField {
        align-self: flex-start;
        background-color: #91172C;
        border-radius: 50%;
        margin-top: auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        overflow: hidden;

        input[type="file"] {
            // appearance: none;
            // width: 1px;
            // height: 1px;
            // overflow: hidden;
            // clip: rect(0 0 0 0);
            cursor: pointer;
            position: absolute;
            display: none;
            width: 100%;
            height: 100%;
          //  opacity: 0;
        }

        label {
          pointer-events: none;
        }
    }
    &__feedFormFileDoc {
        background-color: white;
        border: 2px solid #91172C;
        border-radius: 35px;
        display: flex;
        gap: 6px;
        align-items: center;
        color: #2A2A2A;
        font-family: Raleway;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;

        padding: 8px 16px;
        max-width: 200px;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        img {
            cursor: pointer;
        }
    }
    &__aside {
        align-self: flex-start;
        max-width: 30%;
        width: 100%;

        @media (max-width: 1600px) {
          max-width: calc(35% - 30px);
        }
        @media (max-width: 1200px) {

          grid-row: 2/3;
          max-width: none;
        }

        &_sticky {
            position: sticky;
            top: 50px;
        }
    }
    &__asideBlock {
        border-radius: 35px;
        background-color: #FFFFFF;
        padding: 32px;

        @media (max-width: 480px) {
          padding: 10px;
        } 

        & > * {
            &:not(:last-child) {
                margin: 0 0 32px;
            }
        }
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    &__form {
        background-color: #F8F8F8;
        border-radius: 50px;
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 18px 24px;
        input {
            background-color: transparent;
            border: none;
            outline: none;
            color: #6F6F6F;
            font-size: 20px;
            font-weight: 500;
            line-height: calc(24 / 20);
            width: 100%;
        }
        button {
            background-color: transparent;

            @media (max-width: 480px) {
              padding: 12px; 
            }
        }
    }   
    &__filter {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__filterItem {
        background-color: #F8F8F8;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
        padding: 18px 60px;
        text-align: center;

        @media (max-width: 600px) {
          padding: 18px; 
        }
    }
    &__filterItemActive {
        background-color: #91172C;
        color: #FFFFFF;
    }
    &__users {

    }
    &__usersTitle {
        border-bottom: 2px solid #F8F8F8;
        font-size: 24px;
        font-weight: 500;
        line-height: calc(28 / 24);
        padding: 0 0 20px;

        @media (max-width: 480px) {
          font-size: 20px; 
        }

        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
    &__usersList {
        li {
            width: fit-content;
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }
}

.symbol_counter {
    //position: absolute;
    //right: 40px;
    //bottom: 40px;
    margin-left: auto;
    margin-right: 21px;
    font-family: Saira;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #2A2A2A;
    &RED {
        color: #E11F1F;
    }
}

.symbols_message {
    background-color: white;
    z-index: 80;
    position: absolute;
    bottom: 85px;
    left: 50%;
    padding: 14px 24px 14px 24px;
    gap: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px #00000014;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #2A2A2A;
}

.user {
    display: grid;
    gap: 6px 14px;
    &__avatar {
        background-color: #E1193A;
        border-radius: 50%;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 62px;
        height: 62px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        align-self: end;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);
    }
    &__level {
        background-color: #FF9900;
        border-radius: 30px;
        width: 200px;
        display: flex;
        justify-content: center;
        gap: 6px;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        align-self: start;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        line-height: calc(20 / 16);
        padding: 6px 13px;
        span {
            &:last-child {
                font-family: "Saira", "sans-serif";
            }
        }
    }
}

.wall__feedFormFileField,
.symbols_message,
.symbol_counter,
.submit_button {
    opacity: 0;
    transition: opacity 0.6s ease-in-out; /* Плавный переход */
}

.show {
    opacity: 1; /* Показываем элемент */
}

.page {
  :global(.userList) {
    position: relative;
    
    @media (max-width: 1440px) {
      padding: 70px!important;
      column-gap: 40px!important;
    }
    @media (max-width: 1200px) {
      padding: 30px!important;
    }
    @media (max-width: 1000px) {
      flex-direction: column-reverse;

      & > div {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      padding: 20px!important; 
    }

    & > div {
      & > div {
        justify-content: center;
      }
    }
  }

  & > section {
    :global(.section__header) {
      display: grid;
      grid-template-columns: auto auto 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr auto;
        align-items: start;
        align-content: start;
        row-gap: 30px;
      }
    }
    :global(.section__counter) {
      @media (max-width: 768px) {
        width: max-content;
      }
    }
    .users_sort {
      @media (max-width: 768px) {
        grid-column: 1/3;
        justify-self: start;
      }
    }
  }

  .users_sort {
    justify-self: end;

    position: relative;
    width: max-content;
    min-width: 300px;

    .value {
      background-color: var(--white);
      border-radius: 35px;
      padding: 18px 30px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
      cursor: pointer;

      color: var(--black);
      font-size: 18px;
      font-weight: 500;

      &._active {
        &::before {
          transform: translate(3px, 0) rotate(45deg);
        }
        &::after {
          transform: translate(-3px, 0) rotate(-45deg);
        }

        & + div {
          opacity: 1;
          visibility: visible;
        }
      }

      &::before, &::after {
        content: '';
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
        width: 9px;
        height: 2px;
        background-color: #2A2A2A;
      }
      &::before {
        transform: translate(3px, 0) rotate(-45deg);
      }
      &::after {
        transform: translate(-3px, 0) rotate(45deg);
      }

    }

    .select {
      position: absolute;
      bottom: -12px;
      transform: translate(0, 100%);
      width: 100%;
      z-index: 2;

      background-color: var(--white);
      border-radius: 35px;
      padding: 18px 0;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;

      & > div {
        width: 100%;
        padding: 20px 30px;
        cursor: pointer;

        color: var(--black);
        font-size: 18px;
        font-weight: 500;

        transition: all .3s ease;
        
        &._active {
          background-color: #F8F8F8;
        }

        &:hover {
          background-color: #F8F8F8;
        }
      }
    }
  }
}

.awaitingFriend {
  background-color: white;
  border-radius: 35px;
  padding: 28px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_avatar {
    position: relative;
    width: 140px;
    height: 140px;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_name {
    margin-top: 18px;
    font-family: Raleway;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.011em;
    text-align: center;
  }
  &_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 14px;
    margin-top: 36px;

    button {
      background-color: transparent;
      border: 2px solid var(--main-color);
      color: var(--main-color);
    }
  }
  &_button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &Accept {
      background-color: #91172C;
    }
    &Decline {
      background-color: white;
      border: 2px solid #91172C;
    }
  }
}

.illustration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
  }
}

.swiper_controls {
  width: 100%;
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: 1000px) {
    width: max-content!important; 
  }
  @media (max-width: 480px) {
    span {
      display: none;
    } 
  }
  
  & > * {
    &:disabled {
      background-color: rgba(180, 180, 180, 0.65);
      cursor: auto;
    }
    &:first-child {
      flex-direction: row-reverse;
      & > img {
        transform: rotate(-180deg);
      }
    }
  }
  &__more {
    align-self: flex-end;
  }
}

.friendBlock {
  border-radius: 35px;
  background-color: white;
  padding: 22px 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 600px) {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_avatar {
    position: relative;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    row-gap: 14px;
    padding: 12px 18px;

    @media (max-width: 600px) {
      align-items: center; 
    }

    &_name {
      font-family: Raleway;
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.011em;
      text-align: left;
      color: #2A2A2A;

      @media (max-width: 1000px) {
       font-size: 22px; 
      }
    }
  }
  &_buttons {
    display: flex;
    align-items: center;
    column-gap: 12px;
    position: relative;

    @media (max-width: 600px) {
      position: absolute;
      right: 20px;
      top: 20px; 
    }

    &_threeDot {
      cursor: pointer;
      background-color: #F8F8F8;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;

      @media (max-width: 600px) {
        width: 40px;
        height: 40px; 
      }
    }
  }
}

.dropdownMenu {
  position: absolute;
  box-shadow: 0px 0px 4px 0px #0000001F;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  z-index: 99;
  width: 263px;
  top: 70px;
  overflow: hidden;

  @media (max-width: 1000px) {
    right: 0; 
  }

  button {
    padding: 12px 24px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    display: flex;
    align-items: center;
    column-gap: 12px;
    &:hover {
      background-color: #F8F8F8;
    }
  }
}

.usersFilter {
  padding: 32px;
  border-radius: 35px;
  background-color: white;
  width: 30%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.wall__form {
  background-color: #F8F8F8;
  border-radius: 50px;
  display: flex;
  column-gap: 12px;
  align-items: center;
  padding: 10px 24px;
  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: #6F6F6F;
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
  }
  button {
    background-color: transparent;
  }
}

.wall__filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wall__filterItem {
  background-color: #F8F8F8;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: calc(24 / 20);
  padding: 18px 60px;
  text-align: center;
}

.wall__filterItemActive {
  background-color: #91172C;
  color: #FFFFFF;
}
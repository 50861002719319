
.modal_body_wrapper {
  border-radius: 50px;
  max-width: 90%!important;
  z-index: 1000;

  @media (max-width: 1440px) {
    border-radius: 35px; 
  }

  & > button {
    display: none!important;
  }
}

.modal_body {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 60px;

  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 70px;

  @media (max-width: 1600px) {
    grid-template-columns: 60% 40%; 
  }
  @media (max-width: 1440px) {
    grid-template-columns: 1fr;
    justify-items: center;
    border-radius: 35px;
    padding: 50px;
  }
  @media (max-width: 768px) {
    border-radius: 26px;
    padding: 30px 20px; 
  }

  & > div {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    &:nth-child(2) {
      @media (max-width: 1440px) {
        grid-row: 1/2; 
      }
    }
  }

  b {
    font-size: 64px;
    font-weight: 600;

    @media (max-width: 1440px) {
      font-size: 48px; 
    }
    @media (max-width: 480px) {
      font-size: 32px; 
    }
  }

  p {
    font-size: 20px;

    @media (max-width: 1440px) {
      font-size: 18px; 
    }
  }

  .btns {
    height: 100%;
    justify-self: flex-end;
    display: grid;
    justify-content: start;
    align-items: end;
    grid-template-columns: auto auto;
    gap: 18px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;

      button {
        width: 100%;
      }
    }
    @media (max-width: 480px) {
      button {
        padding: 14px;
      } 
    }

    button:nth-child(2) {
      background-color: transparent;
      border: 2px solid var(--main-color);
      color: var(--main-color);
    }
  }

  img {
    max-width: 600px;
    width: 100%;
  }
}
.eventPage {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 160px;
}

.eventBackground {
  border-radius: 35px;
  width: 100%;
  height: 338px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.eventPage_firstDeck {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.eventInfo {
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  
  @media (max-width: 1440px) {
    padding: 0 50px; 
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    row-gap: 30px;
  }
  @media (max-width: 768px) {
    padding: 0 20px; 
  }
  @media (max-width: 480px) {
    padding: 0; 
  }

  &_left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 32px;
  }
}

.eventHeader {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: left;
}

.eventTime {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 10px; 
  }

  &_block {
    display: flex;
    justify-content: flex-start;
    column-gap: 6px;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
    }
  }
}

.eventDescription {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.eventButton {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 18px 50px;
  border-radius: 50px;
  background-color: #91172C;
  color: white;
  cursor: pointer;

  &.cancelParticipate {
    background-color: #817E7E;
  }
}

.eventOrganization {
  display: flex;
  justify-content: flex-start;
  column-gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}

.eventClub {
  background-color: #F8F8F8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding: 6px 34px 6px 6px;
}

.eventClub_avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.eventClub_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_grey {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #817E7E;
  }
  &_org {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2A2A2A;
  }
}

.clubInfo__clients_wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  background-color: #F8F8F8;
  border-radius: 50px;
  padding-right: 34px;
}

.clubInfo__clients {
  display: flex;
}

.clubInfo__client {
  box-sizing: content-box;
    border: 4px solid #F8F8F8;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: -18px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
}

.clubInfo__others {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 0;
}

.eventPage_Deck {
  display: flex;
  flex-direction: column;
  row-gap: 46px;
}

.eventPage_regularHeader {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
}

.mapWrapper {
  width: 70%;
  height: 500px;
  border-radius: 50px;
  overflow: hidden;
}

.eventPage_gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.eventPage_gallery_photo {
  max-width: 300px;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.photogallery_modal {
  z-index: 1000;
  
  @media (max-width: 1400px) {
    padding: 0;
  }

  .photogallery_modal_body {
    position: relative;
    background-color: transparent!important;
    
    @media (max-width: 1400px) {
      max-width: 80vw;
      height: 100vh;
      top: auto;
      transform: translate(-50%, 0);
    }
  
    & > button {
      background-color: var(--black)!important;

      &::before, &::after {
        background-color: var(--white)!important;
      }
    }
  }
}

.photogallery_slide {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  // opacity: 0!important;

  @media (max-width: 1400px) {
      height: 100vh;
  }

  &:global(.swiper-slide-visible) {
    opacity: 1!important;
  }

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
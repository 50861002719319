.error {
  padding: 20px 0;
  text-align: center;
  color: red;
  font-size: 20px;
}

.contestForm {
  margin: 60px 0;
  & > h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
  }

  @media (max-width: 480px) {
    & > h2 {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.form {
  box-shadow: 0px 0px 8px 0px #0000001f;
  border-radius: 50px;
  margin-top: 46px;

  @media (max-width: 768px) {
    border-radius: 36px;
  }

  @media (max-width: 480px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    border-radius: 24px;
  }
}

.whitePart {
  padding: 70px;
  display: flex;
  column-gap: 60px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 50px;
    gap: 32px;
  }

  @media (max-width: 768px) {
    padding: 34px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
}

.grayPart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 46px;
  padding-bottom: 46px;
  background-color: #f8f8f8;
  border-radius: 0 0 50px 50px;
}

.choosed_files_wrapper {
  margin-top: 20px;
  gap: 8px;
  display: flex;
  flex-flow: wrap;
}

.fileBlock {
  max-width: 300px;
  padding: 12px 20px;
  background-color: #48903A;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;

  img {
    display: block;
  }
}

.filesDropzone {
  width: 100%;
  border: 4px dashed #48903a;
  border-radius: 35px;
  padding: 80px 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 60px 0;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
    border-radius: 26px;
  }

  @media (max-width: 480px) {
    padding: 30px 0;
    border-radius: 16px;
  }
}

.filesDropzone.dragOver {
  background-color: #f0f0f0;
}

.filesDropzone_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;

  @media (max-width: 768px) {
    row-gap: 24px;
  }

  @media (max-width: 480px) {
    row-gap: 16px;

    > :last-child {
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 22px;
      padding: 14px 32px !important;
    }
  }
}

.filesDropzone_cloud {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      inline-size: 80%;
    }
  }
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
    }
  }
}

.description_input {
  border-radius: 35px;
  padding: 30px;
  margin-top: 22px;
  resize: none;
  width: 100%;
  background-color: #f8f8f8;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #a0a0a0;
  height: 235px;

  @media (max-width: 768px) {
    border-radius: 26px;
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    height: 200px;
  }

  @media (max-width: 480px) {
    border-radius: 16px;
    padding: 14px;
    line-height: 18px;
    height: 160px;
  }
}

.description_inputLittle {
  height: auto;
  border-radius: 50px;
  padding: 20px 30px;
}

.description_inputZone {
}
/* Notification.css */
.notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.notification p {
    margin: 0;
    font-size: 14px;
}

.notification button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
}

.accordion {
  display: grid;
  gap: 20px;
  margin: 0;
  padding: 0;

  & > li {
    position: relative;
    list-style: none;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 20px 0;
    overflow: hidden;
    border-bottom: 2px solid #EDEDED;

    & > p {
      font-size: 32px;
      font-weight: 500;

      @media (max-width: 768px) {
        font-size: 26px; 
      }
      @media (max-width: 480px) {
        font-size: 22px; 
      }
    }

    & > div {
      max-width: 90%;
      display: grid;
      align-content: start;
      max-height: 0px;
      height: 100%;
      font-size: 0;
      opacity: 0;

      transition: max-height 0.3s ease, margin-top 0.3s ease;

      p {
        color: #525252;
        font-size: 20px;
      }
    }

    i {
      position: absolute;
      top: 20px;
      right: 40px;
      width: 40px;
      height: auto;
      aspect-ratio: 1/1;
      background-color: var(--main-color);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1200px) {
        right: 30px;
        width: 30px;
      }
      @media (max-width: 768px) {
        right: 0px; 
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 22px;
        height: 2px;

        @media (max-width: 1000px) {
          width: 16px; 
        }
      }
      &::after {
        transition: all .2s ease;
        transform: rotate(90deg);
      }
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 10;
      cursor: pointer;

      &:checked + div {
        max-height: none;
        margin-top: 30px;
        opacity: 1;
      }

      &:checked + div + i {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.achievements {

  & > div:first-child {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 30px;
    }
  }

  &__body {
    background-color: #FFFFFF;
    border-radius: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 1440px) {
      //padding: 50px;
    }
    @media (max-width: 1200px) {
      //gap: 50px;
    }
    @media (max-width: 1000px) {
      border-radius: 36px;
    }
    @media (max-width: 375px) {
      //padding: 18px;
    }
  }
  &__body_myAwards {
    background: #F8F8F8;
    border-radius: 50px;
    padding: 70px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
}


.myAwards_block {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 22px 26px;
  border-radius: 35px;
  column-gap: 18px;
  &_image {
    height: 106px;
    min-width: 106px;
    max-width: 106px;
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.awards_select {
  width: 350px;
  position: relative;
  
  @media (max-width: 480px) {
    width: 100%; 
  }
}

.awards_selected {
  box-shadow: 0px 0px 4px 0px #0000001F;
  border-radius: 35px;
  background-color: white;
  justify-content: space-between;
  font-family: Raleway;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.011em;
  text-align: left;
  padding: 18px 30px;
  cursor: pointer;
}

.awards_selectItems {
  font-family: Raleway;
  box-shadow: 0px 0px 4px 0px #0000001F;
  overflow: hidden;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  top: 70px;
  letter-spacing: -0.011em;
  text-align: left;
  border-radius: 35px;
  position: absolute;
  background-color: white;
  z-index: 99;
  width: 100%;
  div {
    padding: 19px 30px;
    cursor: pointer;
    &:hover {
      background-color: #F8F8F8;
    }
  }
}

.promoMessage {
  width: 245px;
  background-color: white;
  z-index: 80;
  position: absolute;
  bottom: 80px;
  left: 160px;
  padding: 14px 24px 14px 24px;
  gap: 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 8px 0px #00000014;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #2A2A2A;
}

.achievements__body_high {
  display: flex;
  flex-direction: column;
  row-gap: 46px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 70px;

  @media (max-width: 1440px) {
    padding: 50px; 
  }
  @media (max-width: 1000px) {
    padding: 30px 20px; 
  }

  & > p {
    width: 70%;
    text-align: center;
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;

    @media (max-width: 1000px) {
      width: 100%;
      padding: 0 20px;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .rubicks {
    display: flex;
    background-color: #48903A;
    padding: 18px 36px;
    padding: 18px 36px 18px 36px;
    gap: 6px;
    border-radius: 30px;
    color: white;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    span:last-child {
      //styleName: Medium/Numbers;
      font-family: Saira;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
  }
}

.awards_grid {
  background-color: #91172C;
  padding: 46px 70px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 1440px) {
    padding: 35px 50px; 
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1000px) {
    padding: 20px; 
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.award_card {
  border-radius: 24px;
  background-color: white;
  padding: 8px 8px 24px 8px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  cursor: pointer;
  &_image {
    background-color: #F8F8F8;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    //max-height: 284px;
    //min-height: 284px;
    //height: 100%;
    position: relative;
    display: flex; /* Добавлено */
    align-items: center; /* Добавлено */
    justify-content: center; /* Добавлено */
    &_IMG {
      width: 100%; /* Изменено */
      height: auto; /* Изменено */
      max-width: 100%; /* Добавлено, чтобы изображение не выходило за пределы */
      object-fit: contain;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 108px;
    p {
      font-family: Raleway;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      //max-height: 56px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      font-family: Saira;
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
    }
  }
}

.close_icon {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
}

.modalNotEnough {
  max-width: 800px !important;
}

.modalTrade {
  max-width: 574px !important;
  padding: 50px !important;
  .modal_image {
    width: 326px;
    height: 326px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .modal_info {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    text-align: left;
    width: 100%;
    color: #2A2A2A;
    h1 {
      text-align: left;
    }
    p {
      font-size: 20px;
      line-height: 24px;
      span {
        font-family: 'Saira', sans-serif;
        color: var(--main-color);
        font-weight: 500;
        line-height: 24px;
        column-gap: 6px;
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
  .modal_buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    .modal_button {
      cursor: pointer;
      font-size: 20px;
      line-height: 24px;
      width: 100%;
      padding: 18px;
      border-radius: 50px;
      font-weight: 500;
    }
    .modal_buttonAccess {
      color: white;
      background-color: var(--main-color);
    }
    .modal_buttonDecline {
      color: var(--main-color);
      border: 2px solid var(--main-color);
    }
  }
}

.modal {

  & > div {
    padding: 70px;
    border-radius: 50px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    align-items: center;
  }

  h1 {
    font-family: Raleway;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    span {
      color: #91172C;
    }
  }

  p {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 40px;
  }
}

.message {
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  border-radius: 35px;
  background-color: #FFE7DF;
  padding: 22px 26px;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.copyPromo {
  width: 100%;
  width: auto;
}

.promo {
  border: 3px solid var(--main-color);
  width: 100%;
  border-radius: 35px;
  padding: 22px 26px;
  display: flex;
  column-gap: 20px;
  align-items: center;

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
  }

  &_info {
    width: 100%;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 14px;

    p {
      font-family: Raleway;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    @media (max-width: 1000px) {
      grid-column: 2/3; 
    }

    h2 {
      color: #2A2A2A;
      font-size: 24px;
      line-height: 28px;

      @media (max-width: 768px) {
        font-size: 22px; 
      }
    }
    &_button {
      font-size: 16px;
      line-height: 20px;
      color: white;
      background-color: #48903A;
      padding: 6px 13px;
      border-radius: 30px;
      text-align: center;
      width: 180px;
      span {
        font-family: Saira;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: left;

      }
    }
  }
  &_code {
    display: flex;
    column-gap: 12px;

    @media (max-width: 1000px) {
      grid-column: 1/3; 
    }

    &_go {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #91172C;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_copy {
      width: 280px;
      border-radius: 50px;
      background-color: #F8F8F8;
      display: flex;
      position: relative;
      justify-content: space-between;
      padding: 6px 6px 6px 30px;
      align-items: center;
      transition: background-color 0.5s ease, color 0.5s ease;

      @media (max-width: 600px) {
        width: 100%; 
      }
      @media (max-width: 480px) {
        padding: 0px; 
      }

      p {
        text-align: left;

        @media (max-width: 480px) {
          font-size: 18px;
          padding: 0 0 0 20px;
        }
      }
      &_Copied {
        background-color: #d2d0d0;
        p {
          font-weight: 500;
        }
      }
      &_round {
        cursor: pointer;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .awards_grid {
    grid-template-columns: 1fr;
  }
}
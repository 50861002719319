.tag {
    background-color: #F8F8F8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    cursor: pointer;

    span {
        font-family: "Saira", "sans-serif";
        font-size: 20px;
        font-weight: 500;
        line-height: calc(24 / 20);

        @media (max-width: 768px) {
          font-size: 18px; 
        }
        @media (max-width: 480px) {
          font-size: 16px; 
        }
    }
}
